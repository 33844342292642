import React, { useContext, useEffect, useState } from "react";
import { Button, InputBox, Form } from "@lipihipi/ui-components";
import { Box, IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { login } from "../../Service/Service";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AppContext } from "../../App";
import { ActionType } from "../../Reducer";
import {
  ACCESS_DENIED,
  DASHBOARD,
  FORGOT_PASSWORD,
  USER_BLOCK,
} from "../../RouteConstant";
import { USER_TYPES } from "../../constant";
import { Link } from "react-router-dom";
import { LoginSchema } from "./validationSchema";
import handleToast from "../../utils/Toast";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useContext<any>(AppContext);
  const [initialValues, setInitialValues] = React.useState<any>({
    email: "",
    password: "",
  });
  const email = localStorage.getItem("email");
  useEffect(() => {
    console.log(email);
    setInitialValues({
      email: email,
      password: "",
    });
  }, [email]);
  const onLoginSuccess = (res: any) => {
    // Navigate to access denied page if user type is 2

    if (
      ![USER_TYPES.subrecruiter, USER_TYPES.employer].includes(
        res.user.user_type
      )
    ) {
      navigate(ACCESS_DENIED);
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: false,
      });
      return;
    }

    // Navigate to block page if user's status is false.
    if (!res.user?.status) {
      navigate(USER_BLOCK);
      dispatch({
        type: ActionType.SET_LOADING_FLAG,
        payload: false,
      });
      return;
    }

    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: res.user,
    });

    navigate(DASHBOARD);
  };

  const OnFailure = (err: any) => {
    console.log(err.data);
    handleToast(err?.data?.message);
    // console.log(err, "after login email failure");
  };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(values: any) => {
        return new Promise((rs, rj) => {
          login(
            values,
            (res: any) => {
              onLoginSuccess(res);
              rs("");
            },
            (err: any) => {
              OnFailure(err);
              rj("");
            }
          );
        });
      }}
      render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
        return (
          <>
            <InputBox
              id="email"
              name="email"
              label="Corporate Email ID"
              autoComplete="on"
              placeholder="Enter here..."
              fullWidth
              required
              onChange={handleChange}
              sx={{ mb: 3 }}
            />
            <InputBox
              id="password"
              name="password"
              fullWidth
              type={!showPassword ? "password" : "text"}
              required
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
              placeholder="Entere here..."
              label="Enter Password"
            />

            <Box
              display="flex"
              justifyContent="end"
              mb={3}
              sx={{
                "& a": {
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
            >
              <Link to={FORGOT_PASSWORD}>Forgot Password</Link>
            </Box>
            <Button
              shape="primary"
              className="ml-3"
              type="submit"
              size="large"
              loading={isSubmitting}
              disabled={!isValid}
              onClick={isValid ? submitForm : () => {}}
              fullWidth
            >
              Login
            </Button>
          </>
        );
      }}
    />
  );
};

export default Login;
