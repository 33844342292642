import React, { useEffect, useRef, useState,useContext } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, Form, SelectBox, Text } from "@lipihipi/ui-components";
import { NotVerifiedIcon, VerifiedIcon } from "../../Icons";
import {
  FiberManualRecord,
  CurrencyRupee,
  WorkHistory,
  MoreVert,
} from "@mui/icons-material";

import { getAppliedCandidates } from "../../Service/Service";
import Loader from "../../Components/Loader";
import NoDataFound from "../../Shared/NoDataFound";
import { useNavigate, useParams } from "react-router-dom";
import { CANDIDATE_APPLIED_ITEM_KEYS } from "./constants";
import NotFitCandidateModal from "../ShortlistedCandidates/NotFitCandidate";
import handleToast, { success } from "../../utils/Toast";
import ShortlistCandidateModal from "./ShortlistCandidateModal";
import { replaceParamsInUrl } from "../../utils/common";
import { JOBSEEKERDETAIL } from "../../RouteConstant";
import { AppContext } from "../../App";

const MENU_ITEM = [
  { id: CANDIDATE_APPLIED_ITEM_KEYS.not_fit, label: "Not Fit" },
  {
    id: CANDIDATE_APPLIED_ITEM_KEYS.shortlist,
    label: "Shortlist",
  },
];

const AppliedCandidates = () => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [notFitConfirm, setNotFitConfirm] = useState(false);
  const [shortlistConfirm, setShortlistConfirm] = useState(false);
  const candidateData = useRef<Record<string, any> | undefined>();
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const { state }= useContext<any>(AppContext)
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (
    e: React.MouseEvent<HTMLLIElement>,
    data: Record<string, any>
  ) => {
    candidateData.current = data;
    switch ((e.target as HTMLLIElement).id) {
      case CANDIDATE_APPLIED_ITEM_KEYS.not_fit:
        setNotFitConfirm(true);
        break;

      case CANDIDATE_APPLIED_ITEM_KEYS.shortlist:
        setShortlistConfirm(true);
        break;
    }

    handleClose();
  };

  useEffect(() => {
    setLoading(true);
    getAppliedCandidates(
      id || selectedValue || null,
      (data: Record<string, any>) => {
        setData(data?.candidateDetail);
        setFilterOptions(
          data?.jobTitle?.map(({ id, job_title }: any) => ({
            value: id,
            text: job_title,
          }))
        );
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [selectedValue, id, refresh]);

  const onCardClick = (candidate: Record<string, any>) => {
    const userId = candidate?.user_id;
    const jobId = candidate?.job_id;
    if(state?.user?.recruiter_company_info?.company_logo != null){
      navigate(
        replaceParamsInUrl(JOBSEEKERDETAIL, {
          jobId,
          userId,
        }),
        {
          state: { fromApp: true },
        }
      );
    }else{
      handleToast('Please upload logo to view profile')
    }
  };

  return (
    <>
      <SecondaryLayout currentActive={1}>
        <Box
          mb={1}
          display="flex"
          alignItems="end"
          justifyContent="space-between"
        >
          <Text variant="h3">
            Applied Candidates ({!loading ? data?.length || 0 : "..."})
          </Text>
          <div style={{ width: "20%" }}>
            {!id && (
              <Form>
                <SelectBox
                  value={selectedValue}
                  onChange={setSelectedValue}
                  values={"all"}
                  placeholder="Select a job"
                  disabled={!filterOptions.length}
                  items={filterOptions}
                  label=""
                />
              </Form>
            )}
          </div>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
          {loading ? (
            <Loader />
          ) : data.length ? (
            data.map((candidate: Record<string, any>, index) => {
              return (
                <Card
                  sx={{ cursor: "pointer" }}
                  px={2}
                  py={2}
                  disableHeader
                  onCardClick={() => onCardClick(candidate)}
                >
                  <Box display="flex">
                    <Box
                      pr={1}
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      rowGap={3}
                    >
                      <Box>
                        <Box
                          sx={{ mb: 1 }}
                          display="flex"
                          alignItems="center"
                          columnGap={1.5}
                        >
                          <Text variant="h3">
                            {candidate?.get_user?.fname || ""}{" "}
                            {candidate?.get_user?.lname || ""}
                          </Text>

                          <Box
                            display="flex"
                            justifyContent="center"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            {parseInt(candidate?.get_user?.verified_user) ? (
                              <>
                                <VerifiedIcon size={18} />
                                <Text
                                  sx={{
                                    color: "#00C385",
                                    fontSize: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  ID Verified
                                </Text>
                              </>
                            ) : (
                              <>
                                <NotVerifiedIcon size={18} />
                                <Text
                                  sx={{
                                    color: "#EB5757",
                                    fontSize: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  Not Verified
                                </Text>
                              </>
                            )}
                          </Box>
                        </Box>

                        <Text
                          variant="h4"
                          sx={{ fontWeight: 600, color: "#595959" }}
                        >
                          {candidate?.get_user_profile?.skills}
                        </Text>
                      </Box>

                      <Box display="flex" flexDirection="column" rowGap={1.5}>
                        <Text
                          variant="h4"
                          sx={{ color: "#5A5A5A", fontWeight: 400 }}
                        >
                          {candidate?.get_user_profile?.profile_summary}
                        </Text>

                        <Box display="flex" alignItems="center" columnGap={2}>
                          <Text
                            variant="caption"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                            }}
                          >
                            <WorkHistory
                              sx={{ fontSize: 16, color: "#161458" }}
                            />
                            {candidate?.get_user_profile?.total_experience}{" "}
                            Years
                          </Text>
                          <Text variant="body2">
                            <FiberManualRecord sx={{ fontSize: 8 }} />
                          </Text>
                          <Text
                            variant="caption"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 0.5,
                            }}
                          >
                            <CurrencyRupee
                              sx={{ fontSize: 16, color: "#161458" }}
                            />
                            {candidate?.get_user_profile?.current_salary} LPA
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                    >
                      <IconButton
                        sx={{
                          color: "rgba(172, 172, 172, 1)",
                        }}
                        size="small"
                        id="basic-button"
                        aria-controls={open ? `basic-menu-${index}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id={"basic-menu-" + index}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={(event: React.MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                          handleClose();
                        }}
                        elevation={2}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {MENU_ITEM.map((item) => (
                          <MenuItem
                            id={item.id}
                            key={item.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMenu(e, candidate);
                            }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Box>
                </Card>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </Box>
      </SecondaryLayout>
      <NotFitCandidateModal
        open={notFitConfirm}
        onSuccess={() => {
          handleToast("Removed Candidate successfully", success);
          setRefresh((p) => !p);
        }}
        handleClose={() => {
          setNotFitConfirm(false);
        }}
        jobId={candidateData.current?.job_id}
        userId={candidateData.current?.user_id}
      />

      <ShortlistCandidateModal
        open={shortlistConfirm}
        onSuccess={() => {
          handleToast("Candidate shortlisted successfully", success);
          setRefresh((p) => !p);
        }}
        handleClose={() => {
          setShortlistConfirm(false);
        }}
        jobId={candidateData.current?.job_id}
        userId={candidateData.current?.user_id}
      />
    </>
  );
};

export default AppliedCandidates;
