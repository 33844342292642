import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton } from "@mui/material";
import { Button, Text, ImageCropper, Form } from "@lipihipi/ui-components";
import { addMedia, uploadPublic } from "../../Service";
import { ASSET_URL } from "../../constant";
import handleToast, { success } from "../../utils/Toast";

const MediaDrawer = ({ setOpen, open, setToggle }: any) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: "50rem",
        },
      }}
      onClose={() => setOpen(false)}
    >
      <Box
        borderBottom="1px solid #eee"
        px={3}
        py={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="h3">Upload image</Text>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Form
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={(values: any) => {
          addMedia(values, (res: any) => {
            handleToast(res.message, success);
            setToggle((prev: any) => !prev);
            setOpen(false);
          });
        }}
        render={() => {
          return (
            <>
              <Box
                overflow="auto"
                height="calc(100vh - (97px + 73px))"
                p={3}
                sx={{
                  "& .row": {
                    display: "flex",
                    gap: "1rem",
                  },

                  "& .d-flex": {
                    display: "flex",
                  },

                  "& .justify-content-center": {
                    justifyContent: "center",
                  },

                  "& .align-items-center": {
                    alignItems: "center",
                  },

                  "& button": {
                    cursor: "pointer",
                  },

                  "& .col-md-6": {
                    flexGrow: 1,
                    minWidth: "30%",
                  },

                  "& > div": {
                    "& > div": {
                      padding: 0,
                      background: "transparent",
                      border: "none",
                      borderRadius: 0,
                    },
                  },
                }}
              >
                <ImageCropper
                  id="image"
                  name={"galleries"}
                  previewPic={""}
                  required
                  width={250}
                  ratio={1 / 1}
                  accept={["image/jpeg", "image/jpg", "image/png"]}
                  getAssetUrl={ASSET_URL}
                  // uploadFile={uploadPublic}
                />
              </Box>

              <Box
                borderTop="1px solid #eee"
                p={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </>
          );
        }}
      />
    </Drawer>
  );
};

export default MediaDrawer;
