import { Card, Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";

const SelfIntroduction = ({ about }: any) => {
  return (
    <Card disableAction heading="About me">
      <Box display="flex" flexDirection="column" rowGap={1.5}>
        <Text>{about}</Text>
      </Box>
    </Card>
  );
};

export default SelfIntroduction;
