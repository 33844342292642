import React from "react";
import { DatePicker as MaterialDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import vars from "../../theme/variables";
import createPalette from "@mui/material/styles/createPalette";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const { primaryFont, primaryColor, errorColor, greyLight, grey, greyDark } =
  vars;

interface IDatepicker {
  name?: string;
  label?: string;
  views?: any; //readonly DateView[]
  sx?: Object;
  value?: any;
  onChange?: any;
  required?: boolean;
  slotProps?: any; //DatePickerSlotsComponentsProps<unknown>
}

const customPalette = createPalette({
  primary: {
    main: primaryColor,
  },
  grey: {
    "100": greyLight,
    "200": grey,
    "300": greyDark,
  },
  error: {
    main: errorColor,
  },
});

const theme = createTheme({
  typography: {
    fontFamily: primaryFont,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "183%",
          color: customPalette.grey[300],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {},
        root: {
          "&:hover:not(.Mui-disabled, .Mui-error)::before": {
            borderColor: customPalette.grey[200],
            borderWidth: 1,
          },

          "&:after": {
            borderColor: customPalette.primary.main,
          },
          "&:before": {
            borderColor: customPalette.grey[200],
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: customPalette.error.main,
        },
        root: {
          fontWeight: 400,
          fontSize: "1rem",
          color: customPalette?.grey[100],

          "&.Mui-focused": {
            color: customPalette?.grey[100],
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        ".MuiPickersDay-root.Mui-selected": {
          backgroundColor: `${customPalette.primary.main} !important`,
        },
      }),
    },
  },
});

export const Datepicker: React.FC<IDatepicker> = ({
  name,
  label,
  views,
  sx,
  slotProps,
  value,
  onChange,
  required,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = {
    root: {
      width: "100%",
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MaterialDatePicker
          value={value}
          onChange={onChange}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          sx={{
            ...classes.root,
            sx,
          }}
          slotProps={{
            textField: {
              variant: "standard",
              required,
              onClick: (e) => setIsOpen(true),
            },
            ...slotProps,
          }}
          label={label}
          views={views}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
