import React from "react";
import { Modal } from "../../../Components/Modal";
import { Button, Text } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { changeRecruiterStatus } from "../../../Service";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess?: (message?: string) => void;
  recruiterData: Record<string, any>;
};

const ACTIVE_STATUS = "Active";

export const BlockRecruiter = ({
  open,
  handleClose,
  onSuccess,
  recruiterData,
}: Props) => {
  const isBlocked = recruiterData.status !== ACTIVE_STATUS;
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = () => {
    if (recruiterData) {
      setLoading(true);

      const { id, status } = recruiterData;

      changeRecruiterStatus(
        { id, status: status === "Active" },
        () => {
          handleClose();
          onSuccess?.(
            `Recruiter ${isBlocked ? `unblocked` : `blocked`} Successfully`
          );
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  React.useEffect(() => {
    return () => setLoading(false);
  }, []);
  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        // title={"Confirm your action."}
      >
        <Grid
          container
          gap={3}
          justifyContent={"center"}
          direction={"column"}
          mb={1.5}
        >
          <Grid item textAlign={"center"}>
            <h2>{isBlocked ? "Unblock" : "Block"}</h2>
            <Text variant="body2">Please ensure and then confirm!</Text>
          </Grid>

          <Grid item container gap={3} justifyContent={"center"}>
            <Grid item>
              <Button
                variant="outlined"
                disabled={loading}
                onClick={handleClose}
              >
                No, Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                loading={loading}
                onClick={handleConfirm}
              >
                Yes, {isBlocked ? "Unblock" : "Block"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default BlockRecruiter;
