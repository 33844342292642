export const JOB_POST_FORM_NAMES = {
  job_title: "job_title",
  job_type: "job_type",
  total_openings: "total_openings",
  job_categories: "job_categories",
  job_locations: "job_locations",
  minimum_experience: "minimum_experience",
  maximum_experience: "maximum_experience",
  minimum_annual_salary: "minimum_annual_salary",
  maximum_annual_salary: "maximum_annual_salary",
  key_skills: "key_skills",
  graduation_candidate: "graduation_candidate",
  post_graduation_candidate: "post_graduation_candidate",
  doctor_candidate: "doctor_candidate",
  description: "description",
  short_description: "short_description",
  must_have: "must_have",
  good_to_have: "good_to_have",
  role: "role",
  industry: "industry",
  specialization: "specialization",
  hide_salary:"hide_salary"
};

export const JOB_API_NAME_MAPPING = {
  id: "id",
  min_exp: JOB_POST_FORM_NAMES.minimum_experience,
  max_exp: JOB_POST_FORM_NAMES.maximum_experience,
  min_annual_salary: JOB_POST_FORM_NAMES.minimum_annual_salary,
  min_annual_salary_thousand: null,
  max_annual_salary: JOB_POST_FORM_NAMES.maximum_annual_salary,
  max_annual_salary_thousand: null,
};

export const MULTI_SELECT_FIELDS = [
  JOB_POST_FORM_NAMES.job_categories,
  JOB_POST_FORM_NAMES.graduation_candidate,
  JOB_POST_FORM_NAMES.doctor_candidate,
  JOB_POST_FORM_NAMES.post_graduation_candidate,
];

export const MIN_EXP_COUNT = 25;
export const MAX_EXP_COUNT = 25;
export const MIN_ANNUAL_SALARY_COUNT = 50;
export const MAX_ANNUAL_SALARY_COUNT = 50;
