export type passwordValidationObj = {
  id: number;
  active: boolean;
  isCurrent: boolean;
  title: string;
}

export const passwordValidation: passwordValidationObj[] = [
  { id: 0, active: false, isCurrent: false, title: 'Organisation/Company Information' },
  { id: 1, active: false, isCurrent: false, title: 'Verify Email' },
  { id: 2, active: false, isCurrent: false, title: 'Personal Information' },
  { id: 3, active: false, isCurrent: false, title: 'Verify Phone' },
  { id: 4, active: false, isCurrent: false, title: 'General Information' },
  { id: 5, active: false, isCurrent: false, title: 'Address' },
];