import React from "react";
import { Modal } from "../../Components/Modal";
import { Button } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => Promise<any>;
  title: string;
};

export const ConfirmationModal = ({
  open,
  handleClose,
  onSuccess,
  title,
}: Props) => {
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = () => {
    setLoading(true);
    onSuccess?.()
      .then(() => {
        handleClose();
        setLoading(false);
      })
  };

  React.useEffect(() => {
    return () => setLoading(false);
  }, []);
  return (
    <>
      <Modal open={open} handleClose={handleClose} title={title}>
        <Grid container gap={3} justifyContent={"center"} mt={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              loading={loading}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
