import { ASSET_URL } from "../constant";

export const createItemsFromObjects = <
  T extends Record<string, any | undefined>
>(
  object: T
) => {
  return Object.keys(object).map((key: keyof typeof object) => ({
    value: key,
    label: object[key],
  }));
};

// Function to replace placeholders in a URL template with actual values
export const replaceParamsInUrl = (
  urlTemplate: string,
  params: Record<string, any>
) => {
  let replacedUrl = urlTemplate.replace("?", "");

  for (const param in params) {
    if (param in params) {
      const paramPlaceholder = `:${param}`;
      const paramValue = params[param];

      replacedUrl = replacedUrl.replace(paramPlaceholder, paramValue);
    }
  }

  return replacedUrl;
};

/**
 * Function to open a link in new tab
 */
export const openLinkInNewTab = (
  fileName: string,
  assetURL: string = ASSET_URL
) => {
  window.open(`${assetURL}/${fileName}`, "_blank");
};
