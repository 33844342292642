import React from "react";
import GRAPHIC from "../../Images/address.svg";
import { AuthLayout } from "../../Components";
import { Button, Form, InputBox, SelectBox, Text } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Your Address,",
  secondaryText: "Jobseekers love to know"
};

const CompanyAddress = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = React.useState();

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    navigate('/profile')
  }
  return (
    <AuthLayout currentStep={5} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Hemant, please add your office address
      </Text>

      <Form
        initialValues={{}}
        validationSchema={""}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              <InputBox
                fullWidth
                sx={{ mb: 3 }}
                multiline
                maxRows={3}
                placeholder="Enter here.."
                onChange={() => null}
                label="Address 1"
              />

              <InputBox
                fullWidth
                sx={{ mb: 3 }}
                multiline
                maxRows={3}
                placeholder="Enter here.."
                onChange={() => null}
                label="Address 2"
              />

              <InputBox
                fullWidth
                sx={{ mb: 3 }}
                multiline
                maxRows={3}
                placeholder="Enter here.."
                onChange={() => null}
                label="PIN Code"
              />

              <SelectBox
                sx={{ mb: 3 }}
                value={selectedValue}
                id="0"
                required
                items={[{ id: '0', value: '0', text: 'Karnataka' }, { id: '1', value: '1', text: 'Karnataka' }]}
                label="State"
              />

              <SelectBox
                sx={{ mb: 3 }}
                value={selectedValue}
                id="0"
                required
                items={[{ id: '0', value: '0', text: 'Benguluru' }, { id: '1', value: '1', text: 'Benguluru' }]}
                label="Dist"
              />

              <Button
                type="submit"
                fullWidth
                sx={{ mt: 5, mb: 2 }}
                size="large"
              >
                Save
              </Button>

              <Box display='flex' justifyContent='center'>
                <Link style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                  textDecoration: 'none',
                  color: '#000000',
                }} to="/">Skip for now</Link>
              </Box>
            </>
          )
        }}>

      </Form>
    </AuthLayout>
  )
};

export default CompanyAddress;