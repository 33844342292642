import React from "react";

import {
  Button,
  Form,
  InputBox,
  RichTextEditor,
} from "@lipihipi/ui-components";
import { JOB_POST_FORM_NAMES } from "../../constants";
import { Box, Grid, Modal } from "@mui/material";

export const GoodToHave = ({
  onSubmit,
  initialValues,
  isEdit,
  onDraftSave,
  toggleViewjob,
}: any) => (
  <>
    <Form
      {...(initialValues ? { initialValues } : {})}
      onSubmit={onSubmit}
      render={({ isValid, submitForm, isSubmitting, values }: any) => (
        <>
          <Grid container direction={"column"} gap={2}>
            <Grid item>
              <RichTextEditor
                id={`must-have`}
                label={"Goot to have"}
                name={JOB_POST_FORM_NAMES.good_to_have}
                maxHeight={600}
                uploadFile={(params, config) =>
                  new Promise((res) => {
                    res({} as any);
                  })
                }
                getAssetUrl={""}
              />
            </Grid>
            <Grid item container justifyContent={"space-between"}>
              <Grid item>
                <Button
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  {isEdit ? "Update" : "Publish"}
                </Button>
              </Grid>
              {!+initialValues?.draft && (
                <Box gap={1} display={"inline-flex"}>
                  <Button
                    variant="outlined"
                    disabled={!isValid || isSubmitting}
                    onClick={() => toggleViewjob(values)}
                  >
                    View Job
                  </Button>

                  <Button
                    disabled={!isValid || isSubmitting}
                    variant="outlined"
                    onClick={() => onDraftSave(values)}
                  >
                    Save as draft
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      )}
    />
  </>
);

export default GoodToHave;
