import React, { useEffect } from "react";
import GRAPHIC from "../../Images/company.svg";
import { AuthLayout } from "../../Components";
import { Button, Form, InputBox, SelectBox, Text } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import { updateCompany } from "../../Service/Service";
import { ComapnyInfoSchema } from "./validationSchema";
import { GENERAL_INFO } from "../../RouteConstant";
import { onFailure } from "../../utils/helper";
import { companyStrength } from "../../constant";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Corporate info,",
  secondaryText: "It gives another level of confidence in Job Seekers"
};

const CompanyInfo = () => {
  const navigate = useNavigate();
  const [item, setItem] = React.useState<any>('');
  const [initialValues, setInitialValues] = React.useState<any>({
    company_gst: '',
    company_url: '',
    company_emp_strength: '',
    company_year_establish: 0
  });
  useEffect(() => {
    const company: any = sessionStorage.getItem("company");
    if (company) {
      setItem(company);
    }
  },[]);

  const onSuccess = (data: any) => {
    if (data.success === true) {
      //navigate('/address') api not ready once ready move to address first then dashboard
      navigate(GENERAL_INFO) //use this as of now
    }
  };

  return (
    <AuthLayout currentStep={4} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please provide some information about your {item}
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={ComapnyInfoSchema}
        onSubmit={(values: any) => { updateCompany(values,onSuccess,onFailure)}}
        render={({ handleChange,isValid,isSubmitting,submitForm }: any) => {
          return (
            <>
              <InputBox
                id="company_url"
                name="company_url"
                fullWidth
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                label="Company URL"
                onChange={handleChange}
                required
              />

              <InputBox
                id="company_gst"
                name="company_gst"
                fullWidth
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                label="Company GSTIN Number"
                onChange={handleChange}
              />

              <SelectBox
                id="company_emp_strength"
                name="company_emp_strength"
                sx={{ mb: 3 }}
                values={initialValues.company_emp_strength}
                items={companyStrength}
                label="Employee Strength "
                onChange={handleChange}
                required
              />

              <InputBox
                id="company_year_establish"
                name="company_year_establish"
                fullWidth
                sx={{ mb: 3 }}
                placeholder="Enter here.."
                label="Year of Establishment"
                onChange={handleChange}
                required
              />

              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
                onClick={isValid ? submitForm : () => {}}
                fullWidth
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      >
      </Form>

    </AuthLayout>
  )
};

export default CompanyInfo;