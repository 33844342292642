import React from "react";
import {
  RichTextEditor,
  Form,
  Button,
  InputBox,
} from "@lipihipi/ui-components";
import { Grid } from "@mui/material";

import { AddJobDescriptionSchema } from "../../../../configs/Schemas";
import { JOB_POST_FORM_NAMES } from "../../constants";

export const JobDescription = ({ onSubmit, initialValues }: any) => (
  <>
    <Form
      {...(initialValues ? { initialValues } : {})}
      validationSchema={AddJobDescriptionSchema}
      onSubmit={onSubmit}
      render={({ isValid, submitForm, isSubmitting, values, errors }: any) => (
        <Grid container direction={"column"} gap={3}>
          <Grid item>
            <InputBox
              fullWidth
              required
              label={"Short Description"}
              placeholder="Enter Short Decription"
              name={JOB_POST_FORM_NAMES.short_description}
              multiline
              minRows={4}
              maxRows={6}
              inputProps={{
                maxLength: 150,
              }}
              helperText={`${
                values[JOB_POST_FORM_NAMES.short_description]?.length || 0
              }/150 Characters`}
            />
          </Grid>
          <Grid item>
            <RichTextEditor
              id={`description`}
              label={"Job Description"}
              name={JOB_POST_FORM_NAMES.description}
              required
              maxHeight={600}
              uploadFile={(params, config) =>
                new Promise((res) => {
                  res({} as any);
                })
              }
              getAssetUrl={""}
            />
          </Grid>
          <Grid item>
            <Button disabled={!isValid || isSubmitting} onClick={submitForm}>
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    />
  </>
);

export default JobDescription;
