import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, CustomTable } from "@lipihipi/ui-components";
import { EditIcon } from "../../Icons";
import BlockIcon from "@mui/icons-material/Block";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AddIcon from "@mui/icons-material/Add";
import { getRecruiters } from "../../Service/Service";
import Loader from "../../Components/Loader";
import AddRecruiter from "./AddRecruiter";
import { RECRUITER_ACTION_KEYS } from "./constants";
import { AddRecruiterMoney } from "./AddRecruiterMoney";
import WithdrawRecruiterMoney from "./WithdrawRecruiterMoney";
import handleToast, { error, success } from "../../utils/Toast";
import BlockRecruiter from "./BlockRecruiter";

const createRow = ({
  action,
  index,
  credits,
  ...props
}: {
  index: number;
  fname: string;
  lname: string;
  email: string;
  credits: string;
  status: string;
  action: (actionType: string, data: any) => void;
}) => ({
  index,
  name: `${props.fname} ${props.lname}`,
  email: props.email,
  credits,
  status: props.status,
  action: (
    <>
      <Tooltip placement="top" title="Update a recruiter">
        <IconButton
          size="small"
          onClick={() => action(RECRUITER_ACTION_KEYS.edit, props)}
        >
          <EditIcon sx={{ color: "#161458", fontSize: 20 }} />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title="Add Money Recruiter Wallet">
        <IconButton
          size="small"
          onClick={() => action(RECRUITER_ACTION_KEYS.addMoney, props)}
        >
          <AddIcon sx={{ color: "#161458", fontSize: 20 }} />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title="Money Withdraw">
        <IconButton
          size="small"
          onClick={() => action(RECRUITER_ACTION_KEYS.withdrawMoney, props)}
        >
          <CurrencyRupeeIcon sx={{ color: "#161458", fontSize: 20 }} />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title="Block/Unblock">
        <IconButton
          sx={{
            "&:hover": {
              backgroundColor: "#ffeded",
            },
          }}
          onClick={() => action(RECRUITER_ACTION_KEYS.block, props)}
          size="small"
          color="error"
        >
          <BlockIcon sx={{ color: "#d32f2f", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  ),
});

const TableData = {
  heading: [
    { id: "index", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email id" },
    { id: "credits", label: "Credits available" },
    { id: "status", label: "Status" },
    { id: "actions", label: "Action" },
  ],
};

const Recruiter = () => {
  const [open, setOpen] = useState(false);
  const [openAction, setOpenAction] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const actionData = useRef();

  const recruiterAction = (actionType: string, data: any) => {
    actionData.current = data;
    if (actionType === RECRUITER_ACTION_KEYS.edit) {
      setOpen(true);
      return;
    }
    setOpenAction(actionType);
  };

  const ActionModal = {
    [RECRUITER_ACTION_KEYS.edit]: null,
    [RECRUITER_ACTION_KEYS.addMoney]: AddRecruiterMoney,
    [RECRUITER_ACTION_KEYS.withdrawMoney]: WithdrawRecruiterMoney,
    [RECRUITER_ACTION_KEYS.block]: BlockRecruiter,
  }[openAction];

  useEffect(() => {
    setLoading(true);
    getRecruiters(
      (data: Record<string, any>) => {
        setData(
          data?.recruiters?.map(
            (
              { fname, lname, email, status, ...rest }: Record<string, any>,
              index: number
            ) => {
              return createRow({
                index: index + 1,
                fname,
                lname,
                email,
                status: status ? "Active" : "InActive",
                credits: rest?.wallet?.amount,
                action: recruiterAction,
                ...rest,
              });
            }
          )
        );

        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, [refreshData]);

  const addRecruiterHandler = () => {
    actionData.current = undefined;
    setOpen(true);
  };

  return (
    <>
      <SecondaryLayout currentActive={5}>
        <Card
          heading="Recruiters List"
          action={{
            text: "Add New Recruiters",
          }}
          onClick={addRecruiterHandler}
        >
          <Box mx={-4}>
            {loading ? (
              <Loader />
            ) : (
              <CustomTable data={data} heading={TableData.heading} />
            )}
          </Box>
        </Card>
      </SecondaryLayout>

      <AddRecruiter
        recruiterData={actionData.current}
        open={open}
        onCreate={(message) => {
          message && handleToast(message, success);
          setRefreshData((prev) => !prev);
        }}
        onError={(message) => {
          message?.length &&
            message.forEach((item) => {
              handleToast(item, error, { toastId: item });
            });
        }}
        handleClose={() => setOpen(false)}
      />

      {ActionModal && (
        <ActionModal
          open={!!openAction}
          recruiterData={actionData.current || {}}
          onSuccess={(message) => {
            message && handleToast(message, success);
            setRefreshData((prev) => !prev);
          }}
          handleClose={() => setOpenAction("")}
        />
      )}
    </>
  );
};

export default Recruiter;
