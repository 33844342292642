import React, { useCallback, useRef, useState } from "react";
import { Modal } from "../../../Components/Modal";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { RechargeMoneySchema } from "../../../configs/Schemas";
import { getPaymentOptions } from "../../../Service";
import handleToast, { success } from "../../../utils/Toast";

type Props = {
  open: boolean;
  handleClose: () => void;
  setOpen?: any;
  setRefresh?: any;
};

export const RechargeWalletMoney = ({
  open,
  handleClose,
  setOpen,
  setRefresh
}: Props) => {
  const formRef = useRef();
  const [updatingWallet, setUpdatingWallet] = useState(false);
  const onSuccessPayment = useCallback((res: any) => {
    setRefresh((p:any) => !p);
    setOpen(false);
    handleToast("Wallet updated successfully", success);
  }, []);
  const handleSubmit = (values: Record<string, any>) => {
    return new Promise((rs, rj) => {
      getPaymentOptions(
        values,
        (data: any) => {
          const options = {
            ...data.data,
            handler: onSuccessPayment,
            modal: {
              ondismiss: function () {
                rs("");
              },
            },
          };

          const rzp1 = new (window as any).Razorpay(options);
          rzp1.open();
        },
        () => {
          rs("");
        }
      );
    });
  };

  return (
    <>
      <Modal
        open={open}
        handleClose={updatingWallet ? () => {} : handleClose}
        title={"Add Money to Recruiter’s Wallet"}
      >
        <Form
          formRef={formRef}
          validationSchema={RechargeMoneySchema}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            submitForm,
            isValid,
            isSubmitting,
          }: any) => (
            <Grid
              container
              direction={"column"}
              spacing={5}
              sx={{ paddingBottom: "1rem" }}
            >
              <Grid container item direction={"column"} spacing={3}>
                <Grid item>
                  <InputBox
                    required
                    name={"amount"}
                    fullWidth
                    placeholder="Amount"
                    label="Amount"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Button
                  {...(isValid ? { onClick: submitForm } : {})}
                  disabled={!isValid || isSubmitting}
                  loading={updatingWallet ? false : isSubmitting}
                  variant="contained"
                >
                  {updatingWallet ? "Please wait..." : "Add"}
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Modal>
    </>
  );
};

export default RechargeWalletMoney;
