import { Card, Text } from "@lipihipi/ui-components";
import { NoData } from "../../Icons";

const NoDataFound = () => (
  <Card py={6} disableHeader sx={{ textAlign: "center" }}>
    <NoData />
    <Text variant="h2" sx={{ mt: 2, mb: 1 }}>
      No Data Found!
    </Text>
  </Card>
);

export default NoDataFound;
