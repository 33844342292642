import {
  Button,
  CheckBox,
  Form,
  InputBox,
  SelectBox,
  Text,
} from "@lipihipi/ui-components";
import { Box, Grid } from "@mui/material";
import { AddJobBasicSchema } from "../../../../configs/Schemas";
import {
  JOB_POST_FORM_NAMES,
  MAX_ANNUAL_SALARY_COUNT,
  MAX_EXP_COUNT,
  MIN_ANNUAL_SALARY_COUNT,
  MIN_EXP_COUNT,
} from "../../constants";

export const BasicInfo = ({ onSubmit, inputOptions, initialValues }: any) => {
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={AddJobBasicSchema}
        render={({
          isValid,
          handleChange,
          submitForm,
          isSubmitting,
          values,
        }: any) => {
          return (
            <>
              <Box>
                <InputBox
                  fullWidth
                  required
                  name={JOB_POST_FORM_NAMES.job_title}
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="Job Title"
                />

                <Grid container columnSpacing={2.5}>
                  <Grid item xs={12} lg={6}>
                    <SelectBox
                      sx={{ mb: 3 }}
                      name={JOB_POST_FORM_NAMES.job_type}
                      onChange={handleChange}
                      required
                      disabled={
                        !inputOptions[JOB_POST_FORM_NAMES.job_type]?.length
                      }
                      items={inputOptions[JOB_POST_FORM_NAMES.job_type] || []}
                      label="Job type"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SelectBox
                      sx={{ mb: 3 }}
                      name={JOB_POST_FORM_NAMES.job_categories}
                      required
                      isMulti
                      disabled={
                        !inputOptions[JOB_POST_FORM_NAMES.job_categories]
                          ?.length
                      }
                      items={
                        inputOptions[JOB_POST_FORM_NAMES.job_categories] || []
                      }
                      label="Job Category"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputBox
                      fullWidth
                      required
                      name={JOB_POST_FORM_NAMES.total_openings}
                      sx={{ mb: 3 }}
                      placeholder="Enter openings count"
                      onChange={handleChange}
                      label="No of openings"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SelectBox
                      fullWidth
                      required
                      name={JOB_POST_FORM_NAMES.job_locations}
                      valueModifier={(value: Array<string> | string) =>
                        Array.isArray(value) ? value.join(",") : value
                      }
                      sx={{ mb: 3 }}
                      placeholder="Gurgaon, Pune etc.."
                      freeSolo
                      isMulti
                      label="Job Locations"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <SelectBox
                      fullWidth
                      valueModifier={(value: Array<string> | string) =>
                        Array.isArray(value) ? value.join(",") : value
                      }
                      name={JOB_POST_FORM_NAMES.role}
                      sx={{ mb: 3 }}
                      placeholder="Enter role here.."
                      label="Role"
                      freeSolo
                      isMulti
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <SelectBox
                      sx={{ mb: 3 }}
                      fullWidth
                      valueModifier={(value: Array<string> | string) =>
                        Array.isArray(value) ? value.join(",") : value
                      }
                      name={JOB_POST_FORM_NAMES.industry}
                      placeholder="Enter industry here.."
                      freeSolo
                      isMulti
                      label="Industry"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={5}>
                <Text variant="h3" sx={{ mb: 3 }}>
                  Total Experience
                </Text>

                <Grid container columnSpacing={2.5}>
                  <Grid item xs={6} lg={3}>
                    <SelectBox
                      sx={{ mb: 3 }}
                      onChange={handleChange}
                      id="0"
                      name={JOB_POST_FORM_NAMES.minimum_experience}
                      required
                      items={Array(MIN_EXP_COUNT + 1)
                        .fill(0)
                        .map((_, index) => ({
                          value: `${index}`,
                          text: `${index}`,
                        }))}
                      label="From"
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <SelectBox
                      sx={{ mb: 3 }}
                      onChange={handleChange}
                      id="0"
                      name={JOB_POST_FORM_NAMES.maximum_experience}
                      required
                      items={Array(MAX_EXP_COUNT + 1)
                        .fill(0)
                        .map((_, index) => ({
                          value: `${index}`,
                          text: `${index}`,
                        }))}
                      label="To"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={5}>
                <Text variant="h3" sx={{ mb: 3 }}>
                  Annual Salary
                </Text>

                <Grid container columnSpacing={2.5}>
                  <Grid item xs={6} lg={3}>
                    <SelectBox
                      name={JOB_POST_FORM_NAMES.minimum_annual_salary}
                      sx={{ mb: 3 }}
                      placeholder="In Lakh"
                      onChange={handleChange}
                      required
                      items={Array(MIN_ANNUAL_SALARY_COUNT + 1)
                        .fill(0)
                        .map((_, index) => ({
                          value: `${index}`,
                          text: `${index}`,
                        }))}
                      label="From"
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <SelectBox
                      sx={{ mb: 3 }}
                      name={JOB_POST_FORM_NAMES.maximum_annual_salary}
                      placeholder="In Lakh"
                      onChange={handleChange}
                      required
                      items={Array(MAX_ANNUAL_SALARY_COUNT + 1)
                        .fill(0)
                        .map((_, index) => ({
                          value: `${index}`,
                          text: `${index}`,
                        }))}
                      label="To"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <CheckBox
                    name={JOB_POST_FORM_NAMES.hide_salary}
                    label="Hide Salary"
                    checked={values.hide_salary}
                  />
                </Grid>
              </Box>

              <Box mt={5}>
                <Text variant="h3" sx={{ mb: 3 }}>
                  Skills
                </Text>
                <SelectBox
                  sx={{ mb: 3 }}
                  isMulti
                  freeSolo
                  required
                  valueModifier={(value: Array<string> | string) =>
                    Array.isArray(value) ? value.join(",") : value
                  }
                  inputProps={{
                    multiline: true,
                    minRows: 3,
                    maxRows: 5,
                  }}
                  name={JOB_POST_FORM_NAMES.key_skills}
                  placeholder="Example React, Java etc..."
                  label="Key Skills"
                />
              </Box>

              <Button
                variant="contained"
                disabled={!isValid || isSubmitting}
                onClick={isValid ? submitForm : () => {}}
              >
                Save
              </Button>
            </>
          );
        }}
      />
    </>
  );
};
