import React, { useEffect, useContext } from "react";
import { SecondaryLayout } from "../../Components";
import { Avatar, Box } from "@mui/material";
import { Card, CustomTable, Text } from "@lipihipi/ui-components";
import { ActiveJob, Job, Recruiter, Shortlist, Wallet } from "../../Icons";
import { getDashboard, getWallet } from "../../Service/Service";
import { AppContext } from "../../App";
import { Link } from "react-router-dom";
import { APPLIEDCANDIDATES, SHORTLISTEDCANDIDATES } from "../../RouteConstant";
import { replaceParamsInUrl } from "../../utils/common";
import * as routePaths from "../../RouteConstant";
const jobsTableData = {
  heading: [
    { id: 0, label: "S.No." },
    { id: 1, label: "Job Title" },
    { id: 3, label: "Exp" },
    { id: 4, label: "Applied" },
    { id: 5, label: "Shortlisted" },
    { id: 6, label: "Spend" },
  ],
};

const Dashboard = () => {
  const [data, setData] = React.useState<any>({});
  const [walletValue, setWalletValue] = React.useState<any>();
  const [filterData, setFilterData] = React.useState<any>([]);
  const { state } = useContext<any>(AppContext);
  useEffect(() => {
    getDashboard(onSuccess);
    getWallet(onWalletSuccess);
  }, []);

  const onSuccess = (data: any) => {
    setData(data);
    setFilterData((prevFilterData: any) => {
      return data?.jobs.map((item: any, index: any) => ({
        id: index + 1,
        name: item.job_title,
        Exp: item.hasOwnProperty("min_exp")
          ? `${item.min_exp}-${item.max_exp}`
          : `${item.max_exp}`,
        applied: (
          <Link to={replaceParamsInUrl(APPLIEDCANDIDATES, { id: item.id })}>
            {item.job_applied}
          </Link>
        ),
        shortlisted: (
          <Link to={replaceParamsInUrl(SHORTLISTEDCANDIDATES, { id: item.id })}>
            {item.shortlisted}
          </Link>
        ),
        spend: item.spend_amount,
      }));
    });
  };

  const onWalletSuccess = (data: any) => {
    setWalletValue(data?.wallet?.amount);
  };
  return (
    <SecondaryLayout currentActive={0}>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Card disableAction heading="Dashboard">
          <Box
            justifyContent="space-around"
            display="flex"
            pt={3}
            columnGap={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  mb: 2.5,
                  borderRadius: "20px",
                  border: "1px solid #E1E1E1",
                  background: "#FAFAFA",
                  width: 60,
                  height: 60,
                }}
              >
                <Job />
              </Avatar>
              <Text sx={{ mb: 0.5 }}>Total jobs</Text>
              <Text variant="h3">{data?.total_jobs}</Text>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  mb: 2.5,
                  borderRadius: "20px",
                  border: "1px solid #E1E1E1",
                  background: "#FAFAFA",
                  width: 60,
                  height: 60,
                }}
              >
                <ActiveJob />
              </Avatar>
              <Text sx={{ mb: 0.5 }}>Active jobs</Text>
              <Text variant="h3">{data?.jobsActive}</Text>
            </Box>
            {state?.user?.user_type != 4 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  sx={{
                    mb: 2.5,
                    borderRadius: "20px",
                    border: "1px solid #E1E1E1",
                    background: "#FAFAFA",
                    width: 60,
                    height: 60,
                  }}
                >
                  <Recruiter />
                </Avatar>
                <Text sx={{ mb: 0.5 }}>Recruiters</Text>
                <Text variant="h3">{data?.total_recruiter}</Text>
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  mb: 2.5,
                  borderRadius: "20px",
                  border: "1px solid #E1E1E1",
                  background: "#FAFAFA",
                  width: 60,
                  height: 60,
                }}
              >
                <Shortlist />
              </Avatar>
              <Text sx={{ mb: 0.5 }}>Shortlisted</Text>
              <Text variant="h3">{data?.shortlisted || 0}</Text>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  mb: 2.5,
                  borderRadius: "20px",
                  border: "1px solid #E1E1E1",
                  background: "#FAFAFA",
                  width: 60,
                  height: 60,
                }}
              >
                <Wallet />
              </Avatar>
              <Text sx={{ mb: 0.5 }}>Wallet</Text>
              <Text variant="h3">Rs. {walletValue}</Text>
            </Box>
          </Box>
        </Card>

        {/*
        <Card disableHeader sx={{ px: 0 }}>
          <Text variant="h3" sx={{ mb: 2.5, px: 4 }}>AI Screening Interviews</Text>
          <CustomTable data={TableData.data} heading={TableData.heading} />
        </Card> */}

        <Card disableHeader sx={{ px: 0 }}>
        <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
          <Text variant="h3" sx={{ mb: 2.5, px: 4 }}>Jobs Posted</Text>

          <Text variant="h3" sx={{ mb: 2.5, px: 4 }}><Link to={routePaths.JOBS}>View all</Link></Text>
          </Box>
          <CustomTable bodyRowSx={{ '& a': {textAlign: 'center', display: 'block', color: '#292929', textDecoration: 'none'}}} data={filterData} heading={jobsTableData.heading} />
        </Card>
      </Box>
    </SecondaryLayout>
  );
};

export default Dashboard;
