import * as Yup from "yup";

export const AddRecruiterSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(4, "First name must have at least 4 characters")
    .required("First name is Required"),
  last_name: Yup.string()
    .min(4, "Last name must have at least 4characters")
    .required("Last name is Required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  amount: Yup.number()
    .required("Credits are Required")
    .positive("Credits can't be negative")
    .typeError("Credits must be of number type"),
});

export const EditRecruiterSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(4, "First name must have at least 4 characters")
    .required("First name is Required"),
  last_name: Yup.string()
    .min(4, "Last name must have at least 4characters")
    .required("Last name is Required"),
  email: Yup.string().email("Invalid email").required("Email is Required")
});

export const AddRecruiterMoneySchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount are Required")
    .positive("Amount can't be negative")
    .typeError("Amount must be of number type"),
});

export const WithdrawRecruiterMoneySchema = Yup.object().shape({
  amount_withdraw: Yup.number()
    .required("Amount is Required")
    .positive("Amount can't be negative")
    .typeError("Amount must be of number type"),
});
