import React from "react";

import { Card, Text, Button } from "@lipihipi/ui-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Box, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type Props = {
  job: Record<string, any>;
  onClick?: () => void;
  menuItems?: Array<{
    value: string;
    label: string;
  }>;
  onMenuClick?: (
    item: { value: string; label: string },
    job: Record<string, any>
  ) => void;
  onShortlistedClick?: (job: Record<string, any>) => void;
  onAppliedClick?: (job: Record<string, any>) => void;
};

export const JobCard = ({
  job,
  onClick,
  menuItems,
  onMenuClick,
  onShortlistedClick,
  onAppliedClick,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTimeFromNow = (time: string) => {
    dayjs.extend(relativeTime);
    return dayjs(time).fromNow();
  };

  return (
    <>
      <Card
        key={job.id}
        sx={{ cursor: "pointer" }}
        px={2}
        py={2}
        disableHeader
        onCardClick={onClick}
      >
        <Box display="flex">
          <Box
            pr={1}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            rowGap={3}
          >
            <Text variant="h3">{job?.job_title}</Text>

            <Box display="flex" flexDirection="column" rowGap={1.5}>
              <Text variant="h4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.short_description || "",
                  }}
                ></div>
              </Text>
              <Box display="flex" alignItems="center" columnGap={2}>
                <Text
                  variant="caption"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                  }}
                >
                  <WorkHistoryIcon sx={{ fontSize: 16, color: "#161458" }} />
                  {job?.min_exp} - {job?.max_exp} Years
                </Text>
                <Text variant="body2">
                  <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                </Text>
                <Text
                  variant="caption"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 0.5,
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: 16, color: "#161458" }} />
                  {job.min_annual_salary} - {job.max_annual_salary} LPA
                </Text>
                <Text variant="body2">
                  <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                </Text>
                <Text
                  variant="caption"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 0.5,
                  }}
                >
                  <ControlPointDuplicateIcon
                    sx={{ fontSize: 16, color: "#161458" }}
                  />
                  Points Spend ({job.spend_amount || 0})
                </Text>
              </Box>
            </Box>
          </Box>

          {menuItems?.length && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <IconButton
                sx={{
                  color: "rgba(172, 172, 172, 1)",
                }}
                size="small"
                id="basic-button"
                aria-controls={open ? `basic-menu-${job.id}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={"basic-menu-" + job.id}
                anchorEl={anchorEl}
                open={open}
                onClose={(e, reson) => {
                  if (reson === "backdropClick") {
                    (e as MouseEvent).stopPropagation();
                  }
                  handleClose();
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
                PaperProps={{
                  sx: {
                    boxShadow: "1px 1px 3px 0px lightgray",
                  },
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {menuItems?.map((item) => (
                  <MenuItem
                    key={item.value}
                    onClick={(e) => {
                      e.stopPropagation();
                      onMenuClick?.(item, job);
                      handleClose();
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Box
            mb={0.5}
            display="flex"
            alignItems="center"
            columnGap={2}
            component={"div"}
            onClick={(e) => e.stopPropagation()}
          >
            <Button variant="outlined" onClick={() => onAppliedClick?.(job)}>
              Candidates Applied ({job.job_applied})
            </Button>
            <Button
              variant="outlined"
              onClick={() => onShortlistedClick?.(job)}
            >
              Candidates Shortlisted ({job.shortlisted})
            </Button>
          </Box>
          <Text sx={{ color: "#868D90" }} variant="caption">
            Posted {getTimeFromNow(job.created_at)}
          </Text>

          {/* <Button sx={{ ml: 'auto', display: 'flex', columnGap: 0.5 }} variant="text">
                    <BookmarkBorderIcon sx={{ fontSize: 16 }} />
                    Save
                  </Button> */}
        </Box>
      </Card>
    </>
  );
};

export default JobCard;
