import {
  Button,
  DefaultInfoCard,
  Chips,
  Card,
  Text,
} from "@lipihipi/ui-components";
import { SkillsIcon } from "../../../Icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box, TextField } from "@mui/material";

const SkillsDetails = ({ skills }: any) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null); // [] or null

  return (
    <Card disableAction heading="Skill Set">
      <Box display="flex" flexWrap="wrap" gap={1.5}>
        {skills?.split(",")?.map((el: any) => (
          <Chips label={el} />
        ))}
      </Box>
    </Card>
  );
};

export default SkillsDetails;
