import React, { PropsWithChildren } from "react";
import { Grid } from "@mui/material";
import { Text } from "@lipihipi/ui-components";

import { IModal, Modal } from "../Modal";
import Loader from "../Loader";

type Props = {
  open: boolean;
};

export const LoadingModal = ({
  open,
  children,
  ...props
}: PropsWithChildren<Props & IModal>) => (
  <Modal open={open} {...props}>
    <Grid container direction={"column"} gap={2} alignItems={"center"} py={1}>
      <Loader />
      <Text variant="subtitle2">{children}</Text>
    </Grid>
  </Modal>
);

export default LoadingModal;
