import { Button, Card, Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import React, { useState } from "react";
import NotFitCandidateModal from "../../ShortlistedCandidates/NotFitCandidate";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import { APPLIEDCANDIDATES } from "../../../RouteConstant";
import handleToast, { success } from "../../../utils/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { replaceParamsInUrl } from "../../../utils/common";
import ShortlistCandidateModal from "../../AppliedCandidates/ShortlistCandidateModal";

export const AppliedActions = () => {
  const navigate = useNavigate();
  const { jobId, userId } = useParams();

  const [notFitConfirm, setNotFitConfirm] = useState(false);
  const [shortlistConfirm, setShortlistConfirm] = useState(false);
  return (
    <>
      <Card disableHeader>
        <Text mb={2} variant="h3">
          More Actions
        </Text>
        <Box display="flex" flexDirection="column" rowGap={1.5}>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={() => {
              setShortlistConfirm(true);
            }}
          >
            <PersonOutlineOutlinedIcon sx={{ fontSize: 20, mr: 0.5 }} />
            Shortlist Candidate
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={() => {
              setNotFitConfirm(true);
            }}
          >
            <CardGiftcardOutlinedIcon sx={{ fontSize: 20, mr: 0.5 }} />
            Mark as not fit
          </Button>
        </Box>
      </Card>
      <NotFitCandidateModal
        open={notFitConfirm}
        onSuccess={() => {
          handleToast("Removed Candidate successfully", success);
          navigate(
            replaceParamsInUrl(APPLIEDCANDIDATES, {
              id: jobId,
            })
          );
        }}
        handleClose={() => {
          setNotFitConfirm(false);
        }}
        jobId={jobId || ""}
        userId={userId || ""}
      />

      <ShortlistCandidateModal
        open={shortlistConfirm}
        onSuccess={() => {
          handleToast("Candidate shortlisted successfully", success);
          navigate(replaceParamsInUrl(APPLIEDCANDIDATES, { id: jobId }));
        }}
        handleClose={() => {
          setShortlistConfirm(false);
        }}
        jobId={jobId || ""}
        userId={userId || ""}
      />
    </>
  );
};

export default AppliedActions;
