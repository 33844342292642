import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { verifyUser } from "../../Service";
import handleToast, { success } from "../../utils/Toast";
import { Box, Container } from "@mui/material";
import { Button, Text } from "@lipihipi/ui-components";
import { SITE_URL } from "../../constant";

const VerifyUser = () => {
  const { token } = useParams();
  useEffect(() => {
    let payload:any={}
    payload.token=token
    verifyUser(
        payload,
      (res: any) => {
        handleToast(res.data.message,success)
      },
      (err: any) => {
        console.log(err)
      }
    );
  },[token]);
  return <>
  <Box
      sx={{ background: "#fff" }}
      py={3}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexGrow={1}
        >
          <Box
            mb={2.5}
            sx={{
              width: "125px",
              borderRadius: "18px",
              overflow: "hidden",
              backgroundColor: "#D9D9D9",
              border: "1px solid #D9D9D9",

              "& img": {
                maxWidth: "100%",
                display: "block",
              },
            }}
          >
          </Box>
          <Text align="center" variant="h2" sx={{ mb: 1 }}>
          Thank you to verify your email.  Welcome onboard.  Please login to continue..
          </Text>

          <Button
            onClick={() => {
              window.location.href = SITE_URL;
            }}
          >
            Login
          </Button>
        </Box>
      </Container>
    </Box>
  </>;
};

export default VerifyUser;
