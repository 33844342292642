import vars from "./variables";
import { createTheme } from "@mui/material/styles";

const { primaryFont } = vars;

let theme = createTheme();

theme = createTheme({
  typography: {
    fontFamily: primaryFont,
  },

  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex : "initial"
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          // padding: 0
        }
      }
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0
        },
        primary: {
          fontWeight: 500,
          textDecoration: 'none',
          fontSize: '13px',
          lineHeight: '150%',
          letterSpacing: '0.005em',
          color: '#858585'
        }
      }
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '10px 15px',
          columnGap: '12px',
          borderRadius: '8px',
          '&.Mui-selected': {
            background: '#EBF4FF',

            '& .MuiListItemText-primary': {
              color: '#2378DB'
            }
          }
        }
      }
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '1px'
        }
      }
    },

    MuiList: {
      styleOverrides: {
        root: {
          // padding: 0,
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          textTransform: 'none',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
          color: '#000000',
          minWidth: '1px',

          '&.Mui-selected': {
            color: '#000000',
            fontWeight: 700
          }
        }
      }
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#EB996E',
          height: 4,
        },
        flexContainer: {
          columnGap: 20
        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#00C385'
          },
          '& + .MuiFormControlLabel-label': {
            fontSize: '14px'
          },
        },

        colorPrimary: {
          color: '#E0E0E0'
        }
      }
    },

    MuiCssBaseline: {
      styleOverrides: `
        *, body {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: ${primaryFont}
        }
        body {
          background: #EDEDF2;
        }
        .otp-input {
          font-size: 24px;
          font-weight: 500;
          line-height: 31px;
          color: #1D183E;
          width: 60px;
          text-align: center;
        }
        .otp-input:focus {
          box-shadow: none;
          outline: none
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: textfield;
          margin: 0;
        }

        input[type=number] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
      `,
    },
  }
})

export default theme;