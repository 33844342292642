import { useState, useEffect, useMemo, useCallback } from "react";
import { Box } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, CustomTable, Button } from "@lipihipi/ui-components";
import {
  getAIInterviews,
  getInterviewLink,
  getInterviewReport,
} from "../../Service/Service";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";
import { INVITED_USER } from "../../RouteConstant";
import { openLinkInNewTab, replaceParamsInUrl } from "../../utils/common";
import { ASSET_URL } from "../../constant";

const createRow = ({
  index,
  name,
  position,
  price,
  status,
  restRow,
  onAttemptClick,
  onReportClick,
}: {
  index: number;
  name: string;
  position: string;
  price: string;
  status: string;
  restRow: Record<string, any>;
  onAttemptClick?: (slug: string) => void;
  onReportClick?: (slug: string, prevReport?: string) => void;
}) => ({
  index,
  name: (
    <>
      {!restRow.isFreeAll ? (
        <Link
          style={{ color: "#525252", fontWeight: "bold" }}
          to={replaceParamsInUrl(INVITED_USER, { id: restRow.slug })}
        >
          {name}
        </Link>
      ) : (
        name
      )}
    </>
  ),
  position,
  price,
  status,
  action: (() => {
    if (restRow.isFreeAll) {
      if (
        restRow.attempts &&
        restRow?.attempts?.status?.toLowerCase() === "success"
      ) {
        return (
          <Button
            variant="outlined"
            onClick={() =>
              onReportClick?.(restRow?.slug || "", restRow.attempts.result)
            }
          >
            View Report
          </Button>
        );
      }

      return (
        <Button
          variant="outlined"
          onClick={() => onAttemptClick?.(restRow?.slug || "")}
        >
          Attempt
        </Button>
      );
    }
  })(),
});

const TableData = {
  heading: [
    { id: "index", label: "S.No" },
    { id: "name", label: "Name of Interview" },
    { id: "position", label: "Role/Position" },
    { id: "price", label: "Price" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ],
};

const Interviews = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const onReportClick = useCallback((slug: string, prevReport?: string) => {
    if (prevReport) {
      openLinkInNewTab(prevReport);
      return;
    }

    getInterviewReport(
      slug,
      (res: Record<string, any>) => {
        openLinkInNewTab(res.data);
      },
      () => {}
    );
  }, []);

  const onAttemptClick = useCallback((slug: string) => {
    getInterviewLink(
      slug,
      (res: Record<string, any>) => {
        window.open(res.interview_url, "_blank");
      },
      () => {}
    );
  }, []);

  const tableData = useMemo(() => {
    return data?.map(
      (
        {
          name,
          position,
          price,
          isActive,
          // attempts,
          ...restRow
        }: Record<string, any>,
        index: number
      ) => {
        return createRow({
          index: index + 1,
          name,
          status: isActive ? "Active" : "InActive",
          price: price || "",
          // attempts,
          position,
          restRow,
          onAttemptClick,
          onReportClick,
        });
      }
    );
  }, [data, onAttemptClick, onReportClick]);

  useEffect(() => {
    setLoading(true);
    getAIInterviews(
      (data: Record<string, any>) => {
        setData(data?.data);

        setLoading(false);
      },
      () => setLoading(false)
    );
  }, []);

  return (
    <SecondaryLayout currentActive={7}>
      <Card heading="AI Interviews List" disableAction>
        <Box mx={-4}>
          {loading ? (
            <Loader />
          ) : (
            <CustomTable data={tableData} heading={TableData.heading} />
          )}
        </Box>
      </Card>
    </SecondaryLayout>
  );
};

export default Interviews;
