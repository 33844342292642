import React from "react";
import { Card, InfoRow, Text } from "@lipihipi/ui-components";
import { EditIcon } from "../../../Icons";
import { Avatar, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ASSET_URL } from "../../../constant";

const OrganisationDetails = (data:any) => {
  const navigate = useNavigate()
  return (
    <Card
      onClick={() => navigate('/organisation-details')}
      action={{
        icon: <EditIcon style={{ marginRight: 8 }} />,
        text: 'Edit Details'
      }}
      heading="Organisation Details"
    >
      <Box
        display='flex'
        flexDirection='column'
        rowGap={2}
      >
        <InfoRow heading="Organisation Name" content={data?.data?.company_name} />

        <InfoRow heading="Year of Establishment" content={data?.data?.company_year_establish} />

        <InfoRow heading="No of Employees" content={data?.data?.company_emp_strength} />

        <InfoRow heading="Address" content={data?.data?.company_addr} />

        <InfoRow heading="GSTIN No" content={data?.data?.company_gst} />

        <InfoRow heading="Website URL" content={data?.data?.company_url} />

        <InfoRow
          sx={{ alignItems: 'center' }}
          heading="Company Logo"
          content={
            <Avatar  sx={{ width: 114, height: 114 }
          }
          alt='Company Logo' src={`${ASSET_URL}/${data?.data?.company_logo}`} />}
        />
      </Box>
    </Card>
  )
};

export default OrganisationDetails;