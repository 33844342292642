import React, { useEffect, useState } from "react";

import { Box, Container, Grid } from "@mui/material";
import { Back, Header } from "@lipihipi/ui-components";
import BasicDetails from "./BasicDetails";
import JobDetails from "./Job";
import EducationDetails from "./Education";
import EmployerDetails from "./Employer";
import SkillsDetails from "./Skills";
import HobbyDetails from "./Hobby";
import ProjectDetails from "./Project";
import UploadResume from "./UploadResume";
import ProfilePicture from "./ProfilePicture";
import SelfIntroduction from "./SelfIntroduction";

import { useNavigate, useParams } from "react-router-dom";
import { CONTAINER_MAX_WIDTH } from "../../constant";
import { Logo } from "../../Icons";
import { getCandidateInfo } from "../../Service";
import { DropDown } from "./Dropdown";
import { Loader } from "../../Components";
import AppliedActions from "./Actions/AppliedActions";
import ShortlistActions from "./Actions/ShortlistActions";

const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { jobId, userId } = useParams();

  const [data, setData] = useState<any>();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setLoading(true);
    getCandidateInfo(
      {
        user_id: userId,
        job_id: jobId,
      },
      onSuccess,
      () => {}
    );
  }, [jobId, userId]);

  const onSuccess = (res: any) => {
    if (res.success) {
      setData(res.data);
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        isEmployer
        maxWidth={CONTAINER_MAX_WIDTH}
        logo={<Logo style={{ cursor: "pointer" }} />}
      >
        <DropDown />
      </Header>

      <Box mt={2.5}>
        <Container>
          <Back onClick={handleBack} />

          <Box mt={2.5}>
            {loading ? (
              <Loader />
            ) : (
              <Grid alignItems="flex-start" container columnSpacing={2.5}>
                <Grid
                  flexDirection="column"
                  display="flex"
                  rowGap={2.5}
                  item
                  xs={12}
                  lg={9}
                >
                  {data?.profile && (
                    <>
                      <ProfilePicture profile={data?.profile} />
                      <BasicDetails profileDetail={data?.profile} />
                      <JobDetails profileDetail={data?.profile} />
                    </>
                  )}

                  {data?.education?.length > 0 && (
                    <EducationDetails educations={data?.education} />
                  )}

                  {data?.employment?.length > 0 && (
                    <EmployerDetails employments={data?.employment} />
                  )}

                  {data?.profile?.skills && (
                    <SkillsDetails skills={data?.profile?.skills} />
                  )}

                  {data?.profile?.hobbies && (
                    <HobbyDetails hobbies={data?.profile?.hobbies} />
                  )}

                  {data?.project?.length > 0 && (
                    <ProjectDetails projects={data?.project} />
                  )}

                  {data?.profile?.about_me && (
                    <SelfIntroduction about={data?.profile?.about_me} />
                  )}

                  {data?.profile && data?.profile?.resume_name && (
                    <UploadResume
                      url={data?.profile?.resume_name}
                      name={data?.profile?.resume_original_name}
                      date={data?.profile?.resume_uploaded_date}
                    />
                  )}
                </Grid>
                <Grid position="sticky" top={90} item xs={12} lg={3}>
                  <Box display="flex" flexDirection="column" rowGap={1.5}>
                    {data?.candidateStatus?.action === 1 ? (
                      <AppliedActions />
                    ) : (
                      <ShortlistActions />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Profile;
