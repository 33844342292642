import * as routePaths from "./RouteConstant";
import {
  CompanyInfo,
  GeneralInfo,
  OTP,
  CompanyAddress,
  Profile,
  EditOrganisationDetails,
  EditBasicDetails,
  CompanyBio,
  Dashboard,
  Wallet,
  Achievements,
  Media,
  Interviews,
  Recruiter,
  Roles,
  Jobs,
  ShortlistedCandidates,
  CreateJob,
  JobDetails,
  Benefits,
  Events,
  EditJob,
  AppliedCandidates,
  WalletVerificationWrapper,
} from "./Pages";
import { useContext } from "react";
import { AppContext } from "./App";
import { Navigate, RouteObject } from "react-router-dom";
import PageNotFound from "./Shared/404";
import LoginPage from "./Pages/LoginPage";
import DraftedJobs from "./Pages/Jobs/Drafted";
import ArchivedJobs from "./Pages/Jobs/Archived";
import MobileOTP from "./Pages/Otp/mobile-otp";
import ForgotPassword from "./Pages/ForgotPassword";
import InvitedUser from "./Pages/Interviews/InvitedUser";
import UserBlock from "./Pages/UserBlock";
import AccessDenied from "./Pages/AccessDenied";
import VerifyUser from "./Pages/VerifyUser";
import { USER_TYPES } from "./constant";

const AuthRoutes = ({
  verified = true,
  children,
  blockUsers,
  blockRedirectURI,
}: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.page_loading;

  if (isLoading) {
    return null;
  }

  if (!isLoggedIn) {
    return <Navigate to={routePaths.LOGIN} />;
  }

  // Block route for specific type of users
  if (blockUsers?.includes(state?.user?.user_type)) {
    return <Navigate to={blockRedirectURI} />;
  }

  if (verified) {
    if (!state?.user?.verified) {
      return <Navigate to={routePaths.OTP} />;
    }
    if (
      !state?.user?.contact_no &&
      state.user.user_type !== USER_TYPES.subrecruiter
    ) {
      return <Navigate to={routePaths.GENERAL_INFO} />;
    }
    if (
      state?.user?.verify_mobile === 0 &&
      state.user.user_type !== USER_TYPES.subrecruiter
    ) {
      return <Navigate to={routePaths.MOBILE_OTP} />;
    }
  }

  return <>{children}</>;
};

const PublicRoutes = ({ children }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.page_loading;

  if (isLoading) return null;

  if (isLoggedIn) {
    return <Navigate to={routePaths.DASHBOARD} />;
  }

  return <>{children}</>;
};

export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD_,
        element: (
          <AuthRoutes>
            <Navigate to={routePaths.DASHBOARD} />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.LOGIN,
        element: (
          <PublicRoutes>
            <LoginPage />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.VERIFY_USER,
        element: (
          // <PublicRoutes>
            <VerifyUser />
          // </PublicRoutes>
        ),
      },
      {
        path: routePaths.ACCESS_DENIED,
        element: (
          <PublicRoutes>
            <AccessDenied />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.USER_BLOCK,
        element: (
          <PublicRoutes>
            <UserBlock />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.FORGOT_PASSWORD,
        element: (
          <PublicRoutes>
            <ForgotPassword />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.DASHBOARD,
        element: (
          <AuthRoutes>
            <Dashboard />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EVENTS,
        element: (
          <AuthRoutes>
            <Events />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.OTP,
        element: (
          <AuthRoutes verified={false}>
            <OTP />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.MOBILE_OTP,
        element: (
          <AuthRoutes verified={false}>
            <MobileOTP />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.BENEFITS,
        element: (
          <AuthRoutes>
            <Benefits />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.CREATE_JOB,
        element: (
          <AuthRoutes>
            <CreateJob />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.GENERAL_INFO,
        element: (
          <AuthRoutes verified={false}>
            <GeneralInfo />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.COMPANY_INFO,
        element: (
          <AuthRoutes
            blockUsers={[USER_TYPES.subrecruiter]}
            blockRedirectURI={routePaths.DASHBOARD}
            verified={false}
          >
            <CompanyInfo />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ADDRESS,
        element: (
          <AuthRoutes
            blockUsers={[USER_TYPES.subrecruiter]}
            blockRedirectURI={routePaths.DASHBOARD}
          >
            <CompanyAddress />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.PROFILE,
        element: (
          <AuthRoutes>
            <Profile />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ORGANISATION_DETAILS,
        element: (
          <AuthRoutes>
            <EditOrganisationDetails />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.BASIC_DETAILS,
        element: (
          <AuthRoutes verified={false}>
            <EditBasicDetails />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.COMPANY_BIO,
        element: (
          <AuthRoutes>
            <CompanyBio />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.WALLET,
        element: (
          <AuthRoutes>
            <Wallet />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ACHIEVEMENTS,
        element: (
          <AuthRoutes>
            <Achievements />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.MEDIA,
        element: (
          <AuthRoutes>
            <Media />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.INTERVIEWS,
        element: (
          <AuthRoutes>
            <Interviews />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.INVITED_USER,
        element: (
          <AuthRoutes>
            <InvitedUser />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.RECRUITER,
        element: (
          <AuthRoutes
            blockUsers={[USER_TYPES.subrecruiter]}
            blockRedirectURI={routePaths.DASHBOARD}
          >
            <Recruiter />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ROLES,
        element: (
          <AuthRoutes
            blockUsers={[USER_TYPES.subrecruiter]}
            blockRedirectURI={routePaths.DASHBOARD}
          >
            <Roles />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.JOBS,
        element: <Navigate to={routePaths.LIVE_JOBS} />,
      },
      {
        path: routePaths.LIVE_JOBS,
        element: (
          <AuthRoutes>
            <Jobs />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EDIT_JOB,
        element: (
          <AuthRoutes>
            <EditJob />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.DRAFTED_JOBS,
        element: (
          <AuthRoutes>
            <DraftedJobs />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ARCHIVED_JOBS,
        element: (
          <AuthRoutes>
            <ArchivedJobs />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.JOB_DETAILS,
        element: (
          <AuthRoutes>
            <JobDetails />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.SHORTLISTEDCANDIDATES,
        element: (
          <AuthRoutes>
            <ShortlistedCandidates />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.APPLIEDCANDIDATES,
        element: (
          <AuthRoutes>
            <AppliedCandidates />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.JOBSEEKERDETAIL,
        element: (
          <AuthRoutes>
            <WalletVerificationWrapper />
          </AuthRoutes>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
