import * as Yup from "yup";

const MOBILE_REGEX = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
export const BasicDetailSchema = Yup.object().shape({
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    designation: Yup.string().required("Designation is required"),
    contact_no: Yup.string()
    .matches(MOBILE_REGEX, 'Invalid Mobile No.')
    .required('Mobile No. is required')
});

