import React from "react";
import { shortlistCandidate } from "../../Service";
import ConfirmationModal from "../../Components/ConfirmationModal";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
  userId: string;
  jobId: string;
};

export const ShortlistCandidateModal = ({
  open,
  handleClose,
  onSuccess,
  userId,
  jobId,
}: Props) => {
  const handleConfirm = () => {
    return new Promise((rs, rj) => {
      if (jobId && userId) {
        shortlistCandidate(
          { job_id: jobId, user_id: userId },
          () => {
            handleClose();
            onSuccess?.();
            rs("");
          },
          () => {
            rj("");
          }
        );
      }
    });
  };

  return (
    <>
      <ConfirmationModal
        title={"Confirm your action."}
        {...{ open, handleClose, onSuccess: handleConfirm }}
      />
    </>
  );
};

export default ShortlistCandidateModal;
