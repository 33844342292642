import React from "react";
import { Card, InfoRow, Text } from "@lipihipi/ui-components";
import { EditIcon } from "../../../Icons";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BasicDetails = (data:any) => {
  const navigate = useNavigate()
  return (
    <Card
      onClick={() => navigate('/basic-details')}
      action={{
        icon: <EditIcon style={{ marginRight: 8 }} />,
        text: 'Edit Details'
      }}
      heading="Contact Person Details"
    >
      <Box
        display='flex'
        flexDirection='column'
        rowGap={2}
      >
        <InfoRow heading="Name" content= {`${data?.data?.emp_fname} ${data?.data?.emp_lname}`} />

        <InfoRow heading="Contact No" content={data?.data?.emp_mobile} />

        <InfoRow heading="Designation" content={data?.data?.emp_designation} />
      </Box>
    </Card>
  )
};

export default BasicDetails;