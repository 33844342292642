import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import { LoadingModal, SecondaryLayout } from "../../../Components";
import { Text } from "@lipihipi/ui-components";
import { changeJobStatus, getPostedJobs } from "../../../Service";
import Loader from "../../../Components/Loader";
import { JOBS } from "../../../RouteConstant";
import NoDataFound from "../../../Shared/NoDataFound";
import JobCard from "../../../Components/JobCard";
import { createItemsFromObjects } from "../../../utils/common";
import handleToast, { success } from "../../../utils/Toast";

const MENU_ITEMS = {
  block: "Unblock",
};

const ArchivedJobs = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);

  const unblockClickHandler = (data: Record<string, any>) => {
    setUpdateLoading(true);
    changeJobStatus(
      {
        id: data.id,
        status: true,
      },
      (res: any) => {
        handleToast(res.message, success);
        setUpdateLoading(false);
        setRefresh((p) => !p);
      },
      () => {
        handleToast("Some error occurred while changing status!");
        setUpdateLoading(false);
      }
    );
  };

  const menuClickMap: Partial<Record<keyof typeof MENU_ITEMS, Function>> = {
    // edit: editClickHandler,
    block: unblockClickHandler,
  };

  useEffect(() => {
    setLoading(true);
    getPostedJobs(
      { archive: true },
      (data: Record<string, any>) => {
        setData(data?.jobs);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [refresh]);

  return (
    <>
      <SecondaryLayout currentActive={1}>
        <Box>
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="h3">
              Archived Jobs ({loading ? "..." : data.length || 0})
            </Text>
          </Box>
          <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
            {loading ? (
              <Loader />
            ) : data.length ? (
              data.map((job: Record<string, any>) => {
                return (
                  <JobCard
                    key={job.id}
                    job={job}
                    onClick={() => navigate(`${JOBS}/${job.id}`)}
                    menuItems={createItemsFromObjects(MENU_ITEMS)}
                    onMenuClick={(item, data) =>
                      menuClickMap[item.value as keyof typeof MENU_ITEMS]?.(
                        data
                      )
                    }
                  />
                );
              })
            ) : (
              <NoDataFound />
            )}
          </Box>
        </Box>
      </SecondaryLayout>

      <LoadingModal open={updateLoading} width={250}>
        Updating job status
      </LoadingModal>
    </>
  );
};

export default ArchivedJobs;
