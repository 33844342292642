import React, {
  FC,
  ReactNode,
  useContext,
  Fragment,
  useEffect,
  useState,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  DashboardIcon,
  JobIcon,
  EventsIcon,
  WalletIcon,
  ShortlistedIcon,
  RecruitersIcon,
  RolesIcon,
  InterviewsIcon,
  CompanyProfileIcon,
  AboutIcon,
  BenefitsIcon,
  MediaIcon,
  AchievementsIcon,
} from "../../Icons";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Text } from "@lipihipi/ui-components";
import * as routePaths from "../../RouteConstant";
import { AppContext } from "../../App";
import { ASSET_URL, USER_TYPES } from "../../constant";
import { replaceParamsInUrl } from "../../utils/common";
import { PERMISSION_KEYS } from "../../Pages/Roles/constants";

const IMAGE_URL =
  "https://img.freepik.com/free-photo/digital-painting-mountain-with-colorful-tree-foreground_1340-25699.jpg?w=1800&t=st=1685086330~exp=1685086930~hmac=cb36a2268ce6f2f5b755cb139970b65eac99364324aa4ba0c20c862543fb02cb";

interface IRoute {
  id: number;
  title: string;
  link: string;
  iconDefault: ReactNode;
  iconActive: ReactNode;
  childRoutes?: Array<IRoute>;
  blockForUser?: Array<number>;
  allowedRoles?: Array<string>;
}

const ARR: Array<IRoute> = [
  {
    id: 0,
    title: "Dashboard",
    link: routePaths.DASHBOARD,
    iconDefault: <DashboardIcon />,
    iconActive: <DashboardIcon color="#2378DB" />,
  },
  {
    id: 1,
    title: "Jobs & Postings",
    link: routePaths.JOBS,
    iconDefault: <JobIcon />,
    iconActive: <JobIcon color="#2378DB" />,
    allowedRoles: [PERMISSION_KEYS.job_add, PERMISSION_KEYS.job_edit],
    childRoutes: [
      {
        id: 1.1,
        title: "Live",
        link: routePaths.LIVE_JOBS,
        iconDefault: null,
        iconActive: null,
      },
      {
        id: 1.2,
        title: "Create New",
        link: routePaths.CREATE_JOB,
        iconDefault: null,
        allowedRoles: [PERMISSION_KEYS.job_add],
        iconActive: null,
      },
      {
        id: 1.3,
        title: "Drafted",
        link: routePaths.DRAFTED_JOBS,
        iconDefault: null,
        iconActive: null,
      },
      {
        id: 1.4,
        title: "Archived",
        link: routePaths.ARCHIVED_JOBS,
        iconDefault: null,
        iconActive: null,
      },
    ],
  },
  {
    id: 2,
    title: "My Events",
    link: routePaths.EVENTS,
    iconDefault: <EventsIcon />,
    iconActive: <EventsIcon color="#2378DB" />,
  },
  {
    id: 3,
    title: "My Wallet",
    link: routePaths.WALLET,
    iconDefault: <WalletIcon />,
    iconActive: <WalletIcon color="#2378DB" />,
  },

  {
    id: 4,
    title: "Shortlisted Candidates",
    link: replaceParamsInUrl(routePaths.SHORTLISTEDCANDIDATES, { id: "" }),
    iconDefault: <ShortlistedIcon />,
    iconActive: <ShortlistedIcon color="#2378DB" />,
    allowedRoles: [PERMISSION_KEYS.job_add, PERMISSION_KEYS.job_edit],
  },
  {
    id: 5,
    title: "Recruiters",
    link: routePaths.RECRUITER,
    iconDefault: <RecruitersIcon />,
    iconActive: <RecruitersIcon color="#2378DB" />,
    blockForUser: [USER_TYPES.subrecruiter],
  },
  {
    id: 6,
    title: "Roles & Permissions",
    link: routePaths.ROLES,
    iconDefault: <RolesIcon />,
    iconActive: <RolesIcon color="#2378DB" />,
    blockForUser: [USER_TYPES.subrecruiter],
  },
  {
    id: 7,
    title: "Mock Interviews",
    link: routePaths.INTERVIEWS,
    iconDefault: <InterviewsIcon />,
    iconActive: <InterviewsIcon color="#2378DB" />,
    allowedRoles: [PERMISSION_KEYS.int_add, PERMISSION_KEYS.int_edit],
  },
];

const INFO = [
  {
    id: 8,
    title: "Company Profile",
    link: routePaths.PROFILE,
    iconDefault: <CompanyProfileIcon />,
    iconActive: <CompanyProfileIcon color="#2378DB" />,
  },
  {
    id: 9,
    title: "About Company ",
    link: routePaths.COMPANY_BIO,
    iconDefault: <AboutIcon />,
    iconActive: <AboutIcon color="#2378DB" />,
  },
  {
    id: 10,
    title: "Benefits",
    link: routePaths.BENEFITS,
    iconDefault: <BenefitsIcon />,
    iconActive: <BenefitsIcon color="#2378DB" />,
  },
  {
    id: 11,
    title: "Media Gallery",
    link: routePaths.MEDIA,
    iconDefault: <MediaIcon />,
    iconActive: <MediaIcon color="#2378DB" />,
  },
  {
    id: 12,
    title: "Achievements",
    link: routePaths.ACHIEVEMENTS,
    iconDefault: <AchievementsIcon />,
    iconActive: <AchievementsIcon color="#2378DB" />,
  },
];
interface ISidebar {
  currentActive: number;
}

const NavigationWrapper = ({
  routes,
  isChild,
  depth = 2,
  userState,
  currentActive,
}: {
  routes: typeof ARR;
  isChild?: boolean;
  depth?: number;
  currentActive: number;
  userState?: Record<string, any>;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(0);
  const [open, setOpen] = useState(null);
  const [activeItems, setActiveItems] = useState<number[]>([]);
  const userType = userState?.user?.user_type;
  const roles = userState?.user?.roles
    ? JSON.parse(userState?.user?.roles?.access)
    : null;
  const handleClick = (currId: any) => {
    setActiveItems((prevActiveItems) =>
      prevActiveItems.includes(currId)
        ? prevActiveItems.filter((item) => item !== currId)
        : [...prevActiveItems, currId]
    );
  };
  useEffect(() => {
    setId(currentActive);
    setActiveItems((prevActiveItems) =>
      prevActiveItems.includes(currentActive)
        ? prevActiveItems
        : [...prevActiveItems, currentActive]
    );
  }, [currentActive]);
  // useEffect(() => {
  //   setId(currentActive);
  // }, [currentActive]);
  const checkRoles = userType === USER_TYPES.subrecruiter;
  if (!checkRoles) {
    return (
      <List component="nav">
        {routes.map((obj: any) => {
          const isItemActive = activeItems.includes(obj.id);
          if (obj?.childRoutes) {
            return (
              <Fragment key={obj.id}>
                <ListItem>
                  <ListItemButton
                    selected={isItemActive}
                    onClick={() => handleClick(obj.id)}
                  >
                    <ListItemIcon>
                      {id === obj.id || open === obj.id
                        ? obj.iconActive
                        : obj.iconDefault}
                    </ListItemIcon>
                    <ListItemText primary={obj?.title} />
                    {isItemActive ? (
                      <ExpandLess sx={{ color: "#2378DB" }} />
                    ) : (
                      <ExpandMore
                        sx={{ color: id === obj.id ? "#2378DB" : "#858585" }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse in={isItemActive} timeout="auto" unmountOnExit>
                  <List component="div">
                    {obj?.childRoutes?.map((child: any) => (
                      <ListItem key={child?.id}>
                        <ListItemButton
                          selected={child?.link === location.pathname}
                          onClick={() => {
                            navigate(child?.link);
                          }}
                          sx={{ pl: 6 }}
                        >
                          <ListItemText primary={child?.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            );
          }
          return (
            <ListItem key={obj.id}>
              <ListItemButton
                disableRipple
                onClick={() => {
                  setId(obj.id);
                  navigate(obj.link);
                }}
                selected={isItemActive}
              >
                <ListItemIcon>
                  {id === obj.id ? obj.iconActive : obj.iconDefault}
                </ListItemIcon>
                <ListItemText primary={obj.title} />
                {id === obj.id && (
                  <NavigateNextOutlinedIcon
                    style={{ marginLeft: "auto", color: "#2378DB"}}
                  />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <List component="nav">
      {routes.map((obj) =>
        obj?.blockForUser?.includes?.(userType) ? null : !obj.allowedRoles ||
          (roles &&
            obj.allowedRoles &&
            Object.keys(roles).some((role) =>
              obj.allowedRoles?.includes(role)
            )) ? (
          obj.childRoutes ? (
            <Fragment key={obj.id}>
              <ListItem
                selected={id === obj.id || open === obj.id}
                onClick={() => handleClick(obj.id)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {id === obj.id || open === obj.id
                      ? obj.iconActive
                      : obj.iconDefault}
                  </ListItemIcon>
                  <ListItemText primary={obj?.title} />
                  {open === obj.id ? (
                    <ExpandLess sx={{ color: "#2378DB" }} />
                  ) : (
                    <ExpandMore
                      sx={{ color: id === obj.id ? "#2378DB" : "#858585" }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={open === obj.id} timeout="auto" unmountOnExit>
                <List component="div">
                  {obj?.childRoutes?.map((child) => (
                    <ListItem key={child?.id}>
                      <ListItemButton
                        selected={child?.link === location.pathname}
                        onClick={() => {
                          navigate(child?.link);
                        }}
                        sx={{ pl: 6 }}
                      >
                        <ListItemText primary={child?.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ) : (
            <ListItem key={obj.id}>
              <ListItemButton
                disableRipple
                onClick={() => {
                  setId(obj.id);
                  navigate(obj.link);
                }}
                selected={id === obj.id}
              >
                <ListItemIcon>
                  {id === obj.id ? obj.iconActive : obj.iconDefault}
                </ListItemIcon>
                <ListItemText primary={obj.title} />
                {id === obj.id && (
                  <NavigateNextOutlinedIcon
                    style={{ marginLeft: "auto", color: "#2378DB" }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          )
        ) : null
      )}
    </List>
  );
};

export const Sidebar: FC<ISidebar> = ({ currentActive }) => {
  const { state } = useContext<any>(AppContext);
  const classes = {
    root: {
      display: "flex",
      flexDirection: "column",
      background: "#FDFDFD",
      boxShadow: "0px 1.39706px 2.79412px rgba(0, 0, 0, 0.08)",
      borderRadius: "0px 0px 8px 8px",
      "& .MuiListItem-root": {
        paddingTop: 0.25,
        paddingBottom: 0.25,
      },
      "& a": {
        display: "flex",
        padding: "10px 15px",
        alignItems: "center",
        columnGap: 1.5,
        fontWeight: 500,
        textDecoration: "none",
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "0.005em",
        borderRadius: "8px",
      },
    },
  };
  return (
    <Box>
      <Box
        textAlign="center"
        py={3}
        px={2}
        sx={{
          borderRadius: "8px 8px 0 0",
          // borderTop: `6px solid ${state.user?.verified_user ===true? '#00C385' : '#EB5757'}`,
          backgroundColor: "#EBF4FF",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mx="auto"
          mb={2}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${
              state.user?.recruiter_company_info?.company_logo
                ? `${ASSET_URL}/${state.user?.recruiter_company_info?.company_logo}`
                : IMAGE_URL
            })`,
            height: "150px",
            border: "6px solid #FFFFFF",
            backgroundColor: "#EBF4FF",
            borderRadius: "50%",
            width: "150px",
          }}
        ></Box>
        <Text sx={{ mb: 1 }} variant="h3">
          {state?.user?.fname} {state.user?.lname}
        </Text>
        <Text variant="subtitle2">
          {state?.user?.recruiter_company_info?.emp_designation}
        </Text>
      </Box>
      <Box py={1} sx={classes.root}>
        <NavigationWrapper
          routes={ARR}
          userState={state}
          currentActive={currentActive}
        />
      </Box>
      {state?.user?.user_type !== USER_TYPES.subrecruiter && (
        <Box
          py={2.5}
          // px={1}
          sx={classes.root}
          mt={3}
          style={{ borderRadius: "8px" }}
        >
          <>
            <Text variant="h3" sx={{ padding: "0px 15px", mb: 1.5 }}>
              Company Info
            </Text>
            <NavigationWrapper
              routes={INFO}
              userState={state}
              currentActive={currentActive}
            />
          </>
        </Box>
      )}
    </Box>
  );
};
