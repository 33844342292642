import { SecondaryLayout } from "../../Components";
import { Avatar, Box, IconButton } from "@mui/material";
import { Button, Card, FileUpload, Form } from "@lipihipi/ui-components";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useEffect, useState } from "react";
import { addMedia, config, deleteMedia, getMedia } from "../../Service/Service";
import { ASSET_URL, BASE_URL } from "../../constant";
import { Modal } from "../../Components/Modal";
import axios from "axios";
import { uploadPublic } from "../../Service/FileUploadService";
import MediaDrawer from "./MediaDrawer";
import handleToast, { success } from "../../utils/Toast";

const Media = () => {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [mediaData, setMediaData] = useState<any>([]);

  const dimension = {
    width: 185,
    height: 185,
  }

  useEffect(() => {
    getMedia(onSuccess);
  }, [toggle]);

  // useEffect(() => {
  //   let mediaData = {
  //     galleries: file
  //   }
  //   addMedia(mediaData, onMediaAddSuccess);
  // }, [file]);


  const onDeleteSuccess = (res:any) => {
    handleToast(res.message,success)
    getMedia(onSuccess);
  }

  const onSuccess = (data: any) => {
    setMediaData(data?.galleries);
  };

  return (
    <>
      <SecondaryLayout currentActive={11}>
        <Box
          display='flex'
          flexDirection='column'
          rowGap={2}
        >


          <Card heading="Media Gallery" action={{ text: 'Add Media Gallery' }} onClick={() => setOpen(true)}>
            <Box
              display='flex'
              flexWrap='wrap'
              gap={2}
            >
              {mediaData.length > 0 &&
                mediaData.map((item: any) => {
                  return (
                    <Box textAlign='center' position='relative'>
                      <Avatar
                        sx={{
                          ...dimension,
                          borderRadius: 2,
                          boxShadow: '0px 1.39706px 2.79412px rgba(0, 0, 0, 0.08)'
                        }}
                        alt=""
                        src={`${ASSET_URL}/${item.gallery_images}`}
                      />
                      <IconButton sx={{
                        mt: 1,
                        '&:hover': {
                          backgroundColor: '#ffeded',
                        }
                      }} color="error" onClick={() => deleteMedia(item.id, onDeleteSuccess)}>
                        <DeleteOutlineOutlinedIcon sx={{ color: '#d32f2f' }} />
                      </IconButton>
                    </Box>
                  )
                })
              }

            </Box>
          </Card>
        </Box>
      </SecondaryLayout>
        <MediaDrawer
        setOpen={setOpen}
        open={open}
        setToggle={setToggle}
      />
    </>
  )
};

export default Media;