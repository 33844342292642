import { Back, LeftPanel, OnboardingLeftPanel } from "@lipihipi/ui-components";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { passwordValidation } from "./constant";
import { Header } from "../Header";
import LOGO from '../../Images/logo.svg';

type Data = {
  icon?: string;
  primaryText?: string;
  secondaryText?: string;
  content?: ReactNode;
}

interface IAuthLayout {
  children: React.ReactNode;
  data: Data;
  isLogin?: boolean;
  currentStep?: number;
  isSecondary?: boolean;
}



const AuthLayout: React.FC<IAuthLayout> = ({ currentStep = 0, children, data, isLogin = false, isSecondary = false }) => {
  const { icon, primaryText, secondaryText, content } = data;
  const theme = useTheme();
  const navigate = useNavigate();
  const [steps, setSteps] = useState([...passwordValidation]);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const getCurrentStep = () => {
    const tempSteps = [...steps];
    const currIndex = tempSteps.findIndex((step: { id: number }) => step.id === currentStep);
    const prevIndex = currIndex === 0 ? 0 : currIndex - 1;
    const length = tempSteps.length - 1;
    const nextIndex = currIndex === length ? length : currIndex + 1;

    for (let i = 0; i <= currIndex; i++) {
      tempSteps[i].active = true;
    }

    if (currIndex === prevIndex) {
      tempSteps[currIndex].active = true;
      tempSteps[currIndex].isCurrent = true;
      tempSteps[nextIndex].active = false;
    } else {
      tempSteps[prevIndex].isCurrent = false;
      tempSteps[currIndex].isCurrent = true;
      tempSteps[nextIndex].isCurrent = false;
    }

    setSteps(tempSteps)
  }

  useEffect(() => {
    if (!isLogin) {
      getCurrentStep();
    }

  }, [currentStep])

  const handleBack = () => {
    navigate(-1)
  };

  const STEPS = isSecondary ? {} : { steps: steps };

  return (
    <Grid container>
      {matches && (
        <Grid item md={5}>
          {isSecondary ? (
            <LeftPanel
              icon={icon}
              children={content}
            />
          ) : (
            <OnboardingLeftPanel
              logo={LOGO}
              icon={icon}
              primaryText={primaryText}
              secondaryText={secondaryText}
            />
          )}

        </Grid>
      )}
      <Grid item xs={12} md={7}>
        {!matches && <Header isSecondary />}
        <Box
          px={13}
          display='flex'
          sx={{
            background: '#fff',
            minHeight: {
              xs: 'calc(100vh - 67px)',
              md: '1px',
            },
            height: {
              md: '100vh'
            },
            overflow: 'auto',
            alignItems: {
              md: 'center'
            },
            px: {
              xs: theme.spacing(3),
              md: theme.spacing(5),
              lg: theme.spacing(13),
            }
          }}
        >
          <Box
            width='100%'
            sx={{
              margin: {
                lg: 'auto 0'
              },
              maxWidth: {
                lg: theme.spacing(48)
              },
              py: {
                xs: theme.spacing(3),
                md: theme.spacing(5),
              }
            }}
          >
            {!isLogin && <Box mb={5}><Back {...STEPS} onClick={handleBack} /></Box>}
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
};

export default AuthLayout;