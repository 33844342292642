import React from "react";
import { SecondaryLayout } from "../../Components";
import { Card, Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import { addBenefits, deleteBenefits, getBenefits } from "../../Service/Service";
import Cafeteria from "../../Images/benefits/cafeteria.png";
import ChildCare from "../../Images/benefits/child_care.png";
import Education from "../../Images/benefits/education.png";
import FreeFood from "../../Images/benefits/free_food.png";
import FreeTransport from "../../Images/benefits/free_transport.png";
import Gym from "../../Images/benefits/gym.png";
import Insurance from "../../Images/benefits/insurance.png";
import JobTraining from "../../Images/benefits/job_training.png";
import Relocation from "../../Images/benefits/relocation.png";
import SkillTraining from "../../Images/benefits/skill_training.png";
import TeamOuting from "../../Images/benefits/team_outing.png";
import WorkFromHome from "../../Images/benefits/work_from_home.png";
const Benefits = () => {
  const [checkedInputs, setCheckedInputs] = React.useState<any>({});
  React.useEffect(() => {
    getBenefits(onSuccess);
  }, []);

  const onSuccess = (data: any) => {
    setCheckedInputs(data.benefitList);
  };

  const onFailure = (data: any) => {
    console.log(data)
  };

  const handleBenefitChange = (event: any,key:string) => {
    const { name, checked } = event.target;
    let benefitData = {
      benefit_name: name,
      benefit_image:key
    }
    if (checked) {
      addBenefits(benefitData, onAddDeleteSuccess, onFailure);
    }
    else {
      deleteBenefits(benefitData, onAddDeleteSuccess, onFailure)
    }
  };
  const onAddDeleteSuccess = (data: any) => {
    getBenefits(onSuccess);
  }

  const classes = {
    benefits: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 3,

      '& label': {
        width: 'calc((100% - (48px + 24px)) / 4)',


        display: 'flex',
        cursor: 'pointer',
        height: '180px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',

        '& div': {
          width: '100%',
          border: '1px solid #161458',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          borderRadius: '4px',
          padding: '0 10px'
        },

        '& h4': {
          color: '#161458',
        },

        '& img': {
          marginBottom: '1rem',
          height: '40px'
        },

        '& input': {
          display: 'none',

          '&:checked + div': {
            background: '#b1b3ea'
          },
        }
      },
    },
  }
  return (
    <SecondaryLayout currentActive={10}>
      <Text
        sx={{ mb: 2 }}
        variant="h3"
      >
        Benefits
      </Text>

      <Card disableHeader>
        <Box sx={classes.benefits}>
          <label>
            <input name="health_insurance" type="checkbox" checked={checkedInputs.health_insurance || false} onChange={(event: any) => handleBenefitChange(event,"insurance.png")} />
            <Box>
              <img src={Insurance} alt="" />
              <Text variant="h4">Health Insurance</Text>
            </Box>
          </label>

          <label>
            <input name="cafeteria" type="checkbox" checked={checkedInputs.cafeteria || false} onChange={(event: any) => handleBenefitChange(event,"cafeteria.png")} />
            <Box>
              <img src={Cafeteria} alt="" />
              <Text variant="h4">Cafeteria</Text>
            </Box>
          </label>

          <label>
            <input name="gymnasium" type="checkbox" checked={checkedInputs.gymnasium || false} onChange={(event: any) => handleBenefitChange(event,"gym.png")} />
            <Box>
              <img src={Gym} alt="" />
              <Text variant="h4">Gymnasium</Text>
            </Box>
          </label>

          <label>
            <input name="soft_skill_training" type="checkbox" checked={checkedInputs.soft_skill_training || false} onChange={(event: any) => handleBenefitChange(event,"skill_training.png")} />
            <Box>
              <img src={SkillTraining} alt="" />
              <Text variant="h4">Soft Skill Training</Text>
            </Box>
          </label>

          <label>
            <input name="free_transport" type="checkbox" checked={checkedInputs.free_transport || false} onChange={(event: any) => handleBenefitChange(event,"free_transport.png")} />
            <Box>
              <img src={FreeTransport} alt="" />
              <Text variant="h4">Free Transport</Text>
            </Box>
          </label>

          <label>
            <input name="job_training" type="checkbox" checked={checkedInputs.job_training || false} onChange={(event: any) => handleBenefitChange(event,"job_training.png")} />
            <Box>
              <img src={JobTraining} alt="" />
              <Text variant="h4">Job Training</Text>
            </Box>
          </label>

          <label>
            <input name="education_assistance" type="checkbox" checked={checkedInputs.education_assistance || false} onChange={(event: any) => handleBenefitChange(event,"education.png")} />
            <Box>
              <img src={Education} alt="" />
              <Text variant="h4">Education Assistance</Text>
            </Box>
          </label>

          <label>
            <input name="team_outings" type="checkbox" checked={checkedInputs.team_outings || false} onChange={(event: any) => handleBenefitChange(event,"team_outing.png")} />
            <Box>
              <img src={TeamOuting} alt="" />
              <Text variant="h4">Team Outings</Text>
            </Box>
          </label>

          <label>
            <input name="work_from_home" type="checkbox" checked={checkedInputs.work_from_home || false} onChange={(event: any) => handleBenefitChange(event,"work_from_home.png")} />
            <Box>
              <img src={WorkFromHome} alt="" />
              <Text variant="h4">Work From Home</Text>
            </Box>
          </label>

          <label>
            <input name="relocation" type="checkbox" checked={checkedInputs.relocation || false} onChange={(event: any) => handleBenefitChange(event,"relocation.png")} />
            <Box>
              <img src={Relocation} alt="" />
              <Text variant="h4">Relocation</Text>
            </Box>
          </label>

          <label>
            <input name="child_care" type="checkbox" checked={checkedInputs.child_care || false} onChange={(event: any) => handleBenefitChange(event,"child_care.png")} />
            <Box>
              <img src={ChildCare} alt="" />
              <Text variant="h4">Child care</Text>
            </Box>
          </label>

          <label>
            <input name="free_food" type="checkbox" checked={checkedInputs.free_food || false} onChange={(event: any) => handleBenefitChange(event,"free_food.png")} />
            <Box>
              <img src={FreeFood} alt="" />
              <Text variant="h4">Free Food</Text>
            </Box>
          </label>
        </Box>
      </Card>
    </SecondaryLayout>
  )
};

export default Benefits;