import * as Yup from 'yup';

const MOBILE_REGEX = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

  export const personalInfoSchema = Yup.object().shape({
    contact_no: Yup.string()
    .matches(MOBILE_REGEX, 'Invalid Mobile No.')
    .required('Mobile No. is required'),
    fname:Yup.string().required('First Name is required'),
    lname:Yup.string().required('Last Name is required'),
    designation:Yup.string().required('Desugnation Name is required')
  });