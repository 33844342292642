import { Box, Container, Grid } from "@mui/material";
import { Back,  Header, WalletInfo } from "@lipihipi/ui-components";
import { Logo } from "../../Icons";
import BasicDetails from "./Basic/BasicDetails";
import OrganisationDetails from "./Organisation/OrganisationDetails";
import { useNavigate } from "react-router-dom";
//import { CONTAINER_MAX_WIDTH } from "../../constant";
import { DropDown } from "./Dropdown";
import React, { useEffect } from "react";
import { getProfile} from "../../Service/Service";

const Profile = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1)
  };

  const [profileData, setProfileData] = React.useState<any>({});

  useEffect(() => {
    getProfile(onSuccess, onFailure);
  }, []);

  const onSuccess = (data: any) => {
    setProfileData(data);
  };

  const onFailure = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <Header
        isEmployer
        logo={<Logo style={{ cursor: 'pointer' }} />}
      >

        <DropDown />
      </Header>

      <Box py={2.5}>
        <Container>
          <Back onClick={handleBack} />

          <Box mt={2.5}>
            <Grid alignItems='flex-start' container columnSpacing={2.5}>
              <Grid
                flexDirection='column'
                display='flex'
                rowGap={2.5}
                item
                xs={12}
                lg={9}
              >

                <OrganisationDetails data={profileData?.employer} />

                <BasicDetails data={profileData?.employer}/>

              </Grid>

              <Grid position='sticky' top={90} item xs={12} lg={3}>
                <Box
                  display='flex'
                  flexDirection='column'
                  rowGap={1.5}
                >
                  <WalletInfo amount={profileData?.wallet?.amount} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default Profile;