import { InfoRow, Card } from "@lipihipi/ui-components";
import { Box } from "@mui/material";

const JobDetails = ({ profileDetail }: any) => {
  return (
    <Card heading="Job Details" disableAction>
      <Box display="flex" flexDirection="column" rowGap={2}>
        {profileDetail?.job_type === "0" ? (
          <InfoRow heading="Experience" content="Fresher" />
        ) : (
          <InfoRow
            heading="Current Salary"
            content={`${!profileDetail.is_hide_salary ?profileDetail?.current_salary+' ' +'Lakhs'+' '+ profileDetail?.current_salary_thousand +" "+ 'Thousand PA':"Not disclosed" }`}
          />
        )}

        <InfoRow heading="Industry" content={profileDetail?.industry?.value} />

        <InfoRow
          heading="Functional Area"
          content={profileDetail?.functional_area?.value}
        />
        {profileDetail?.job_type != 0 && (
          <InfoRow
            heading="Notice period"
            content={profileDetail?.notice_period?.value}
          />
        )}
      </Box>
    </Card>
  );
};

export default JobDetails;
