import { Chips, Card } from "@lipihipi/ui-components";
import { Box } from "@mui/material";

const HobbyDetails = ({ hobbies }: any) => {
  return (
    <Card disableAction heading="Hobbies & Interests">
      <Box display="flex" flexWrap="wrap" gap={1.5}>
        {hobbies?.split(",").map((el: any) => (
          <Chips label={el} />
        ))}
      </Box>
    </Card>
  );
};

export default HobbyDetails;
