import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { JobSeekerDetail } from "..";
import CheckWalletModal from "../AppliedCandidates/CheckWalletModal";
import handleToast, { success } from "../../utils/Toast";
import { DASHBOARD } from "../../RouteConstant";

export const WalletVerificationWrapper = () => {
  const { jobId, userId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [valid, setValid] = useState(state?.ignoreWalletCheck);
  const [open, setOpen] = useState(!state?.ignoreWalletCheck);

  const onWalletCheckSuccess = (res: any) => {
    res?.wallet &&
      handleToast(`Updated wallet balance - ${res?.wallet}`, success);
    setValid(res.success);
  };

  const onWalletCheckFail = (message?: string) => {
    message && handleToast(message);
    setOpen(false);
    setValid(false);
    if (state?.fromApp) {
      navigate(-1);
    } else {
      navigate(DASHBOARD);
    }
  };

  return (
    <>
      {valid ? (
        <JobSeekerDetail />
      ) : (
        <CheckWalletModal
          open={open}
          onSuccess={onWalletCheckSuccess}
          onError={onWalletCheckFail}
          handleClose={() => {
            setOpen(false);
          }}
          jobId={jobId || ""}
          userId={userId || ""}
        />
      )}
    </>
  );
};

export default WalletVerificationWrapper;
