import React, { useEffect, useState } from "react";
import { SecondaryLayout } from "../../Components";
import { Box } from "@mui/material";
import { Card, CustomTable } from "@lipihipi/ui-components";
import { getWallet } from "../../Service/Service";
import Loader from "../../Components/Loader";
import RechargeWalletMoney from "./RechargeWallet";
import handleToast, { success } from "../../utils/Toast";
import { AppContext } from "../../App";
import { USER_TYPES } from "../../constant";

function capitalizeFirstLetter(str: string) {
  // Check if the input string is not empty
  if (str.length === 0) {
      return str;
  }
  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function convertTimestamp(timestamp: any) {
  // Create a Date object from the provided timestamp
  const date = new Date(timestamp);

  // Extract the components of the date
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0');
  const hours: any = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const am_pm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format

  // Format the date and time
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${formattedHours}:${minutes} ${am_pm}`;

  return `${formattedDate}, ${formattedTime}`;
}

const createRow = (
  created_at: string,
  transaction_type: string,
  amount: string,
  description: string
) => ({
  created_at,
  transaction_type,
  amount,
  description,
});

const TableData = {
  heading: [
    { id: "created_at", label: "Date" },
    { id: "transaction_type", label: "Transaction Type" },
    { id: "amount", label: "Amount" },
    { id: "description", label: "Source" },
  ],
};

const Wallet = () => {
  const { state } = React.useContext<any>(AppContext);

  const isUserEmployer = state?.user?.user_type === USER_TYPES.employer;

  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [walletValue, setWalletValue] = useState(null);

  useEffect(() => {
    setLoading(true);
    getWallet(
      (data: Record<string, any>) => {
        setData(
          data?.logs?.data?.map(
            ({
              created_at,
              transaction_type,
              amount,
              description,
            }: Record<string, any>) => {
              const modifiedDate = new Date(created_at)
                .toLocaleString()
                .split("/")
                .join("-");
              return createRow(
                convertTimestamp(created_at),
                capitalizeFirstLetter(transaction_type),
                amount,
                description
              );
            }
          )
        );
        setWalletValue(data?.wallet?.amount);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [refresh]);


  return (
    <>
      <SecondaryLayout currentActive={3}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card
            heading={`Wallet History (Available Balance: Rs. ${
              loading ? "..." : walletValue || 0
            })`}
            // Block recharge wallet button for subrecruiters
            {...(isUserEmployer
              ? {
                  action: { text: "Recharge Now" },
                  onClick: () => setOpen(true),
                }
              : { disableAction: true })}
          >
            <Box mx={-4}>
              {loading ? (
                <Loader />
              ) : (
                <CustomTable tableSx={{
                  '& .MuiTableCell-root:not(:first-of-type)': {textAlign: 'center'}
                }} data={data} heading={TableData.heading} />
              )}
            </Box>
          </Card>
        </Box>
      </SecondaryLayout>

      <RechargeWalletMoney
        open={open}
        handleClose={() => setOpen(false)}
        setOpen={setOpen}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default Wallet;
