import React, { useRef } from "react";
import { Modal } from "../../../Components/Modal";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import {
  AddRecruiterSchema,
  EditRecruiterSchema,
} from "../../../configs/Schemas";
import { addRecruiter, updateRecruiter } from "../../../Service";
import handleToast, { success } from "../../../utils/Toast";

type Props = {
  recruiterData?: Record<string, any>;
  open: boolean;
  handleClose: () => void;
  onCreate?: (message?: string) => void;
  onError?: (message?: string[]) => void;
};

export const AddRecruiter = ({
  recruiterData,
  open,
  handleClose,
  onCreate,
  onError,
}: Props) => {
  const formRef = useRef();

  const isEditState = !!recruiterData;
  let initialValues: Record<string, any> | null = null;

  // To handle recruiter edit case
  if (recruiterData) {
    const {
      wallet,
      fname: first_name,
      lname: last_name,
      ...rest
    } = recruiterData;
    initialValues = {
      ...rest,
      first_name,
      last_name,
      amount: wallet?.amount.split(".")[0]
    };
  }

  const handleSubmit = (values: Record<string, any>) => {
    const apiHandler = isEditState ? updateRecruiter : addRecruiter;
    const payload = isEditState
      ? { record_id: recruiterData.id, ...values }
      : values;
    return new Promise((rs) => {
      apiHandler(
        payload,
        () => {
          handleClose();
          onCreate?.(
            !isEditState
              ? "Recruiter added successfully"
              : "Recruiter edited successfully"
          );
          rs("");
        },
        (err: any) => {
          const messages: string[] = Object.values(
            err?.data?.errors || { invalid: err?.data?.message } || {
                error: "Error while adding recruiter",
              }
          );
          onError?.(messages.map((item) => (item)));
          rs("");
        }
      );
    });
  };

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        title={"Add a new recruiter"}
      >
        <Form
          {...(initialValues ? { initialValues } : {})}
          formRef={formRef}
          validationSchema={
            isEditState ? EditRecruiterSchema : AddRecruiterSchema
          }
          onSubmit={handleSubmit}
          render={({
            handleChange,
            submitForm,
            isValid,
            isSubmitting,
            dirty,
          }: any) => (
            <Grid
              container
              direction={"column"}
              spacing={5}
              sx={{ paddingBottom: "1rem" }}
            >
              <Grid container item direction={"column"} spacing={3}>
                <Grid item>
                  <InputBox
                    autoComplete="off"
                    name={"first_name"}
                    fullWidth
                    placeholder="First Name"
                    label="First Name"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item>
                  <InputBox
                    name={"last_name"}
                    fullWidth
                    placeholder="Last Name"
                    label="Last Name"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item>
                  <InputBox
                    name={"email"}
                    fullWidth
                    placeholder="Email Address"
                    label="Email Id"
                    onChange={handleChange}
                    required
                  />
                </Grid>
                {}
                <Grid item>
                  <InputBox
                    disabled={isEditState}
                    type="number"
                    name={"amount"}
                    fullWidth
                    placeholder="Allocate Credits"
                    label="Allocate Credits"
                    onChange={handleChange}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Button
                  {...(isValid ? { onClick: submitForm } : {})}
                  disabled={!(isValid && dirty) || isSubmitting}
                  loading={isSubmitting}
                  variant="contained"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Modal>
    </>
  );
};

export default AddRecruiter;
