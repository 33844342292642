import { Card, Text } from "@lipihipi/ui-components";
import { Box, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const UploadResume = ({ url, name, date }: any) => {
  const handleDownload = () => {
    window.open(url, "_blank");
  };

  return (
    <Card disableAction heading="Attach Resume">
      <Box display="flex" flexDirection="column" rowGap={1.5}>
        <Box display="flex" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <InsertDriveFileOutlinedIcon sx={{ mr: 1 }} />
            <Text variant="h4">{name}</Text>
          </Box>

          <Box display="flex" alignItems="center" ml={2}>
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "#E4F7F3",
                },
              }}
              onClick={handleDownload}
              size="small"
              color="success"
            >
              <FileDownloadOutlinedIcon sx={{ color: "#00A689" }} />
            </IconButton>
          </Box>
          <Text variant="h4">Uploaded on {date}</Text>
        </Box>
      </Box>
    </Card>
  );
};

export default UploadResume;
