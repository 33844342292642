import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { Text, Button } from "@lipihipi/ui-components";

import { changeJobStatus, getPostedJobs } from "../../Service";
import {
  SecondaryLayout,
  LoadingModal,
  Loader,
  JobCard,
} from "../../Components";
import {
  APPLIEDCANDIDATES,
  CREATE_JOB,
  EDIT_JOB,
  JOB_DETAILS,
  SHORTLISTEDCANDIDATES,
} from "../../RouteConstant";
import NoDataFound from "../../Shared/NoDataFound";
import { createItemsFromObjects, replaceParamsInUrl } from "../../utils/common";
import handleToast, { error, success } from "../../utils/Toast";
import { AppContext } from "../../App";
import { PERMISSION_KEYS } from "../Roles/constants";
import { USER_TYPES } from "../../constant";

const Jobs = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const userType = state?.user?.user_type;
  const roles = state?.user?.roles
    ? JSON.parse(state?.user?.roles?.access)
    : null;

  const canUserCreateJob = useMemo(() => {
    if (userType === USER_TYPES.employer) {
      return true;
    }

    return (
      roles &&
      Object.keys(roles).some((role) => role.includes(PERMISSION_KEYS.job_add))
    );
  }, [roles, userType]);

  const canUserEditJob = useMemo(() => {
    if (userType === USER_TYPES.employer) {
      return true;
    }

    return (
      roles &&
      Object.keys(roles).some((role) => role.includes(PERMISSION_KEYS.job_edit))
    );
  }, [roles, userType]);

  const MENU_ITEMS: Record<string, any> = {
    // Disable edit if not permitted.
    ...(canUserEditJob
      ? {
          edit: "Edit",
        }
      : {}),
    block: "Block",
  };

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const editClickHandler = (data: Record<string, any>) => {
    navigate(replaceParamsInUrl(EDIT_JOB, { id: data.id }));
    window.scrollTo(0, 0);
  };

  const blockClickHandler = (data: Record<string, any>) => {
    setUpdateLoading(true);
    changeJobStatus(
      {
        id: data.id,
        status: false,
      },
      (res: any) => {
        handleToast(res.message, success);
        setUpdateLoading(false);
        setRefresh((p) => !p);
      },
      () => {
        handleToast("Some error occurred while changing status!", error);
        setUpdateLoading(false);
      }
    );
  };

  const menuClickMap: Partial<Record<keyof typeof MENU_ITEMS, Function>> = {
    edit: editClickHandler,
    block: blockClickHandler,
  };

  useEffect(() => {
    setLoading(true);
    getPostedJobs(
      { draft: false },
      (data: Record<string, any>) => {
        setData(data?.jobs.filter((job: any) => job.status));
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [refresh]);

  return (
    <>
      <SecondaryLayout currentActive={1}>
        <Box>
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="h3">
              Active Jobs ({loading ? "..." : data.length || 0})
            </Text>
            {canUserCreateJob && (
              <Button variant="contained" onClick={() => navigate(CREATE_JOB)}>
                Post a new job
              </Button>
            )}
          </Box>
          <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
            {loading ? (
              <Loader />
            ) : data.length ? (
              data.map((job: Record<string, any>, index) => {
                return (
                  <JobCard
                    key={job.id}
                    job={job}
                    onClick={() =>
                      navigate(replaceParamsInUrl(JOB_DETAILS, { id: job.id }))
                    }
                    menuItems={createItemsFromObjects(MENU_ITEMS)}
                    onShortlistedClick={(job) => {
                      navigate(
                        replaceParamsInUrl(SHORTLISTEDCANDIDATES, {
                          id: job.id,
                        })
                      );
                      window.scrollTo(0, 0);
                    }}
                    onAppliedClick={(job) => {
                      navigate(
                        replaceParamsInUrl(APPLIEDCANDIDATES, {
                          id: job.id,
                        })
                      );
                      window.scrollTo(0, 0);
                    }}
                    onMenuClick={(item, data) =>
                      menuClickMap[item.value as keyof typeof MENU_ITEMS]?.(
                        data
                      )
                    }
                  />
                );
              })
            ) : (
              <NoDataFound />
            )}
          </Box>
        </Box>
      </SecondaryLayout>
      <LoadingModal open={updateLoading} width={250}>
        Updating Job Status
      </LoadingModal>
    </>
  );
};

export default Jobs;
