import * as Yup from "yup";

export const InviteUserSchema = Yup.object().shape({
  fname: Yup.mixed().required("First name is Required"),
  lname: Yup.mixed().required("Last name is Required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .length(10, "Enter a valid mobile number"),
});
