import * as Yup from "yup";

const GST_REGEX = /^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1})$/;
export const ComapnySchema = Yup.object().shape({
    company_url: Yup.string().url('Company url should be start with https://').required('Company url is required'),
    company_gst: Yup.string().matches(GST_REGEX,'Invalid GSTIN format').nullable(),
    company_emp_strength: Yup.string().required("Company Strength is required"),
    company_name: Yup.string().required("Company name is required"),
    company_addr: Yup.string(),
    company_year_establish: Yup.mixed().required("Company year is required"),
    company_logo: Yup.string().required("Company logo is required"),
});
export const ComapnyBioSchema = Yup.object().shape({
    company_bio: Yup.string().required("Company Bio is required"),
});

