import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SecondaryLayout } from "../../../Components";
import { Card, Text } from "@lipihipi/ui-components";
import { getOptions, postNewJob, updateJob } from "../../../Service";
import { JOB_POST_FORM_NAMES } from "../constants";
import { useNavigate } from "react-router-dom";
import { JOBS } from "../../../RouteConstant";
import { OPTIONS_ID } from "../../../constant";
import {
  BasicInfo,
  JobDescription,
  MustHave,
  GoodToHave,
  DesiredProfile,
} from "./SubForms";
import handleToast, { success } from "../../../utils/Toast";
import { Modal } from "../../../Components/Modal";
import JobDetailCard from "../../JobDetail/JobDetailCard";

const postJobProcess = {
  "Basic Info": BasicInfo,
  "Desired Profile": DesiredProfile,
  "Job Description": JobDescription,
  "Must Have": MustHave,
  "Good To Have": GoodToHave,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? <Box mt={1.5}>{children}</Box> : null}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CreateJob = ({ pageTitle, jobPost, onSuccess, onError }: any) => {
  const isEdit = jobPost && Object.keys(jobPost).length;

  const [open, setOpen] = React.useState<any>();
  const [value, setValue] = React.useState(0);
  const [optionsData, setOptionsData] = React.useState({});
  const [initialData, setInitialData] = React.useState(jobPost);
  const completedTabs = React.useRef<Array<number>>([]);
  const formsValueRef = React.useRef<typeof JOB_POST_FORM_NAMES | undefined>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const postJob = async (values: typeof JOB_POST_FORM_NAMES | undefined) => {
    if (values) {
      const compatiblePayload = Object.keys(values).reduce((acc: any, key) => {
        if (values) {
          switch (key) {
            case JOB_POST_FORM_NAMES.graduation_candidate:
            case JOB_POST_FORM_NAMES.post_graduation_candidate:
            case JOB_POST_FORM_NAMES.doctor_candidate:
            case JOB_POST_FORM_NAMES.job_categories:
              let value = values[key as keyof typeof JOB_POST_FORM_NAMES];
              acc[key] =
                typeof value === "string"
                  ? value.split(",").map((item) => parseInt(item))
                  : value;
              break;

            default:
              acc[key] = values[key as keyof typeof JOB_POST_FORM_NAMES];
          }
        }

        return acc;
      }, {});

      const httpCaller = isEdit ? updateJob : postNewJob;

      return new Promise((rs, rj) => {
        httpCaller(
          compatiblePayload,
          () => {
            rs("");
          },
          () => {
            rj("");
          }
        );
      });
    }
  };

  const nonFinalFormSubmit =
    (panelIndex: number, isLastStep: boolean = false) =>
    (values: any) => {
      return new Promise((rs, rj) => {
        window.scrollTo(0, 0);
        formsValueRef.current = { ...formsValueRef.current, ...values };

        !isLastStep && setValue((p) => ++p);

        if (!completedTabs.current.includes(panelIndex)) {
          completedTabs.current.push(...[panelIndex, panelIndex + 1]);
        }

        if (isEdit) {
          setInitialData(formsValueRef.current);
        }

        if (isLastStep) {
          postJob(formsValueRef.current)
            .then(() => {
              onSuccess?.();
              rs("");
            })
            .catch(() => {
              onError?.();
              rs("");
            });
        } else {
          rs("");
        }
      });
    };

  const onDraftSave = (values: any) => {
    postJob({ ...values, draft: 1 })
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.();
      });
  };

  const toggleViewjob = (data: Record<string, any>) => {
    setOpen(data);
  };

  React.useEffect(() => {
    const optionsIds = {
      [JOB_POST_FORM_NAMES.job_type]: OPTIONS_ID.JobType,
      [JOB_POST_FORM_NAMES.job_categories]: OPTIONS_ID.JobCategory,
      [JOB_POST_FORM_NAMES.graduation_candidate]: OPTIONS_ID.SpecifyGraduation,
      [JOB_POST_FORM_NAMES.specialization]: OPTIONS_ID.Specialisation,
      [JOB_POST_FORM_NAMES.industry]: OPTIONS_ID.Industry,
      [JOB_POST_FORM_NAMES.post_graduation_candidate]:
        OPTIONS_ID.SpecifyPostGraduation,
      [JOB_POST_FORM_NAMES.doctor_candidate]: OPTIONS_ID.SpecifyDoctorate,
    };

    Promise.allSettled(
      Object.values(optionsIds).map((id) => {
        return getOptions({ id });
      })
    ).then((data) => {
      const inputOptions = Object.keys(optionsIds).reduce((acc, key, index) => {
        const dataSet = data[index];
        if (dataSet.status === "fulfilled") {
          acc[key] = dataSet.value.data.options.map(
            ({ id: value, value: text }: any) => ({
              value: `${value}`,
              text,
            })
          );
        } else {
          acc[key] = [];
        }
        return acc;
      }, {} as Record<string, any>);

      setOptionsData(inputOptions);
    });
  }, []);
  return (
    <>
      <Text variant="h3">{pageTitle}</Text>
      <Card sx={{ mt: 2 }} disableHeader>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {Object.keys(postJobProcess).map((key, index) => (
            <Tab
              key={key}
              disabled={isEdit ? false : !completedTabs.current.includes(index)}
              label={key}
              {...a11yProps(0)}
            />
          ))}
        </Tabs>

        {Object.keys(postJobProcess).map((key, index) => {
          const isLastPanel = Object.keys(postJobProcess).length === index + 1;
          return (
            <TabPanel key={key} value={value} index={index}>
              {postJobProcess[key as keyof typeof postJobProcess]({
                onSubmit: nonFinalFormSubmit(index, isLastPanel),
                inputOptions: optionsData,
                initialValues: initialData || formsValueRef.current,
                isEdit,
                onDraftSave,
                toggleViewjob,
              })}
            </TabPanel>
          );
        })}
      </Card>

      <Modal open={!!open} maxWidth="md" handleClose={() => setOpen(null)}>
        <JobDetailCard jobData={open} showBack={false} onClose={()=>setOpen(null)}/>
      </Modal>
    </>
  );
};

export const CreateJobWrapper = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    handleToast("Job created Successfully", success);
    navigate(JOBS);
  };

  const onError = () => {
    handleToast("Error while creating job");
  };

  return (
    <SecondaryLayout currentActive={1}>
      <CreateJob
        title="Create a new Job Posting"
        onSuccess={onSuccess}
        onError={onError}
      />
    </SecondaryLayout>
  );
};

export default CreateJobWrapper;
