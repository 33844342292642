import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import { Modal } from "../../Components/Modal";
import { useEffect, useState } from "react";
import {
  addAchievement,
  getAchievement,
  updateAchievement,
} from "../../Service";
import { AchievementSchema } from "./validationSchema";
import handleToast, { success } from "../../utils/Toast";
const AddModal = ({ isOpen, setOpen }: any) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
  });
  useEffect(() => {
    if (isOpen.id != null) {
      getAchievement(isOpen.id, (res: any) => {
        // console.log(res);
        setInitialValues(res.data);
      });
    }
  }, [isOpen.id]);
  return (
    <>
      <Modal
        open={isOpen.isOpen}
        title="Add Achievement"
        handleClose={() => {
          setOpen({ isOpen: false });
          setInitialValues({ title: "", description: "" });
        }}
      >
        <Form
          initialValues={initialValues}
          validateOnMount={true}
          validationSchema={AchievementSchema}
          onSubmit={(values: any) => {
            if (isOpen.id != null) {
              values.id = isOpen.id;
              updateAchievement(values, (res: any) => {
                setInitialValues({ title: "", description: "" });
                setOpen({ isOPen: false });
                handleToast(res.message, success);
                res("");
              });
            } else {
              addAchievement(values, (res: any) => {
                setInitialValues({ title: "", description: "" });
                setOpen({ isOPen: false });

                handleToast(res.message, success);
                res("");
              });
            }
          }}
          render={({
            handleChange,
            isValid,
            isSubmitting,
            submitForm,
          }: any) => {
            return (
              <>
                <InputBox
                  id="title"
                  name="title"
                  fullWidth
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  label="Title"
                  onChange={handleChange}
                />

                <InputBox
                  id="description"
                  name="description"
                  fullWidth
                  sx={{ mb: 3 }}
                  multiline
                  maxRows={3}
                  placeholder="Enter here.."
                  label="Description"
                  onChange={handleChange}
                />

                <Box mt={3} display="flex" justifyContent="center">
                  <Button
                    disabled={!isValid || isSubmitting}
                    onClick={isValid ? submitForm : () => {}}
                    type="submit"
                    size="large"
                    sx={{ px: 5 }}
                  >
                    Save
                  </Button>
                </Box>
              </>
            );
          }}
        />
      </Modal>
    </>
  );
};
export default AddModal;
